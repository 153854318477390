import { LDFlagSet } from "launchdarkly-react-client-sdk";
import { AllowedPlatform } from "../../models";
import { LaunchDarklyFlag } from "../../utils/constant";
import { PageType } from "@reconvert/reconvert-utils";

export const PageNames: Record<PageType, string | null> = {
  [PageType.PRE_PURCHASE_PRODUCT_PAGE]: "Product page",
  [PageType.PRE_PURCHASE_CART_PAGE]: "Cart page",
  [PageType.PRE_PURCHASE_CART_DRAWER_PAGE]: "Cart drawer",
  [PageType.CHECKOUT]: "Checkout",
  [PageType.POST_PURCHASE_PAGE1]: "Post purchase 1st",
  [PageType.POST_PURCHASE_PAGE2]: "Post purchase 2nd",
  [PageType.THANK_YOU_PAGE]: "Thank you page",
  [PageType.ORDER_STATUS_PAGE]: "Order status page",
};

export const ShopifyCheckoutEditorPage: Record<PageType, string | null> = {
  [PageType.PRE_PURCHASE_PRODUCT_PAGE]: null,
  [PageType.PRE_PURCHASE_CART_PAGE]: null,
  [PageType.PRE_PURCHASE_CART_DRAWER_PAGE]: null,
  [PageType.CHECKOUT]: "information",
  [PageType.POST_PURCHASE_PAGE1]: null,
  [PageType.POST_PURCHASE_PAGE2]: null,
  [PageType.THANK_YOU_PAGE]: "thank-you",
  [PageType.ORDER_STATUS_PAGE]: "order-status",
};

export const ShopifyCheckoutEditorPageCollection: Record<PageType, string | null> = {
  [PageType.PRE_PURCHASE_PRODUCT_PAGE]: null,
  [PageType.PRE_PURCHASE_CART_PAGE]: null,
  [PageType.PRE_PURCHASE_CART_DRAWER_PAGE]: null,
  [PageType.CHECKOUT]: "checkout",
  [PageType.POST_PURCHASE_PAGE1]: null,
  [PageType.POST_PURCHASE_PAGE2]: null,
  [PageType.THANK_YOU_PAGE]: "thank-you-page",
  [PageType.ORDER_STATUS_PAGE]: "order-status-page",
};

export interface StoreInfo {
  storeClientId: number;
  storeUrl: string;
  shopName: string;
  shopOwner: string;
  email: string;
  currency?: string;
  currencySymbol?: string;
  dashboardVersion?: number;
  checkoutVersion?: number;
  isShopifyPlusMerchant?: boolean;
}

export interface View {
  isWidgetView: boolean;
  isAnalyticView: boolean;
}

export interface LinkItem {
  name: string;
  icon: React.JSX.Element;
  activeIcon: React.JSX.Element;
  type: PageType;
  allowedPlatform: AllowedPlatform;
  launchDarklyFlag?: LaunchDarklyFlag;
  disabled?: boolean;
  toolTip?: string;
}

export interface SideBarSection {
  name: string;
  links: LinkItem[];
  enabled: boolean;
}
export interface AppState {
  loading: boolean;
  storeInfo?: StoreInfo;
  error?: string;
  platformStoreId: string;
  isAppBridgePresent: boolean;
  activePage?: PageType;
  isSidebarCollapased: boolean;
  isSidebarSticked: boolean;
  showHelpModal: boolean;
  helpArticleLink?: string;
  helpArticleTitle?: string;
  view: View;
  ldFlags?: LDFlagSet;
  iFrameLink?: string;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  sideBarSections: SideBarSection[];
  checkoutProfileId?: string;
  breadcrumbValue?: string;
  isUnsavedBarEnabled: boolean;
}
