import { Box, Button, Divider, Flex, Skeleton, Spinner, Stack, Text } from "@chakra-ui/react";
import {
  Icons,
  SkeletonCard,
  ToolTip,
  Typography,
  useCurrencyFormatter,
  useFlowBuilderSession,
} from "@reconvert/react-ui-component";
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Draggable, Droppable, DragDropContext, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectAppState, toggleHelpModal } from "../../../app/slice/appSlice";
import { Overview, selectConditionsState, setOverview, setSelectedSlot } from "../../../app/slice/conditionSlice";
import useConditions from "../../../hooks/useConditions";
import ConditionCard from "../../ConditionCard";
import { Condition, ConditionRule, Platform } from "../../../models";
import { APP_PLATFORM } from "../../../config";
import ConditionTooltip from "./ConditionTooltip";
import useUnsavedBar from "../../../hooks/useUnsavedBar";
import { ConditionFact, getConditionText } from "@reconvert/reconvert-utils";
import { selectSlotsState, updateSlotABTestingValue } from "../../../app/slice/slotSlice";
import { ConditionType } from "../../../hooks/useAndOrGrouping";
import OrSeprator from "./AndOrGrouping/OrSeprator";
import AndSeprator from "./AndOrGrouping/AndSeprator";

const SkeletonComponent = () => (
  <Box display="grid" mb={"16px"} mr={3}>
    <SkeletonCard>
      <Stack gap="13px">
        <Flex justifyContent="space-between">
          <Flex gap="8px">
            <Skeleton startColor="gray.400" endColor="gray.400" height="20px" width="140px" />
            <Skeleton startColor="gray.400" endColor="gray.400" height="20px" width="35px" />
          </Flex>
          <Flex gap="8px">
            <Skeleton startColor="gray.400" endColor="gray.400" height="24px" width="24px" />
            <Skeleton startColor="gray.400" endColor="gray.400" height="24px" width="24px" />
            <Skeleton startColor="gray.400" endColor="gray.400" height="24px" width="24px" />
          </Flex>
        </Flex>
        <Skeleton startColor="gray.400" endColor="gray.400" height="12px" width="252px" />
      </Stack>
    </SkeletonCard>
  </Box>
);

const Conditions = () => {
  const dispatch = useAppDispatch();
  const {
    loading: isConditionDataLoading,
    overview,
    conditions,
    selectedConditionId,
    selectedVariantId,
  } = useAppSelector(selectConditionsState);
  const { currencyFormatter } = useCurrencyFormatter();
  const { storeInfo } = useAppSelector(selectAppState);
  const { slots } = useAppSelector(selectSlotsState);
  const { updateConditionStatus, setSelectedConditionId, updateConditionPriority, selectedCondition } = useConditions();
  const { t } = useTranslation();
  const { preventAction } = useUnsavedBar();
  const { setSelectedConditionInSession, setSelectedVariantInSession } = useFlowBuilderSession();
  const conditionsContainerRef = useRef<HTMLDivElement | null>(null);
  const defaultCondition = useMemo(() => {
    return conditions.filter(condition => condition.isDefault);
  }, [conditions]);

  const mouseRef = useRef<any>();

  const formatCurrency = useCallback(
    (value: number) => {
      return currencyFormatter(value, storeInfo?.currency!);
    },
    [currencyFormatter, storeInfo?.currency],
  );

  useEffect(() => {
    if (APP_PLATFORM === Platform.Shopify) {
      setSelectedConditionInSession(selectedConditionId || "");
    } else {
      selectedConditionId && setSelectedConditionInSession(selectedConditionId);
    }
  }, [selectedConditionId]);

  useEffect(() => {
    if (APP_PLATFORM === Platform.Shopify) {
      setSelectedVariantInSession(selectedVariantId || "");
    } else {
      selectedVariantId && setSelectedVariantInSession(selectedVariantId);
    }
  }, [selectedVariantId]);

  useEffect(() => {
    if (!selectedCondition?.variants?.length) return;

    const updatedSlots = slots.map(a => {
      return {
        id: a.id,
        isABTestingEnabled: (selectedCondition?.variants?.filter(v => v.slotId === a.id)?.length ?? 0) > 1,
      };
    });

    dispatch(updateSlotABTestingValue(updatedSlots));

    const slot =
      slots.find(a => a.id === selectedCondition?.variants?.find(v => v.id === selectedVariantId)?.slotId) ?? slots[0];

    dispatch(
      setSelectedSlot({
        ...slot,
        isABTestingEnabled: updatedSlots.find(a => a.id === slot.id)?.isABTestingEnabled ?? false,
      }),
    );
  }, [dispatch, slots.length, selectedCondition?.variants]);

  useEffect(() => {
    const defaultConditionCard = document?.getElementById("defaultCondition");

    if (defaultConditionCard) {
      conditionsContainerRef?.current?.scrollTo({
        top: conditionsContainerRef?.current?.scrollHeight,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document?.getElementById("defaultCondition")]);

  const handleAddConditionClick = useCallback(() => {
    dispatch(setOverview(Overview.ADD_CONDITION_VIEW));
    conditionsContainerRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [dispatch]);

  function updatePosition(arr: Condition[], fromIndex: number, toIndex: number) {
    if (fromIndex < 0 || fromIndex >= arr.length || toIndex < 0 || toIndex >= arr.length) {
      return arr;
    }

    const element = arr.splice(fromIndex, 1)[0];

    arr.splice(toIndex, 0, element);

    return arr;
  }

  const handleOnDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    const removedDefaultCondition = conditions.filter(condition => !condition.isDefault);
    const defaultCondition = conditions.filter(condition => condition.isDefault);

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const updatedConditions = updatePosition(removedDefaultCondition, source?.index, destination?.index);

    const updatedConditionsIdAndPriority = updatedConditions.map(condition => ({
      id: condition.id,
      priority: condition.priority,
    }));

    updatedConditionsIdAndPriority.forEach((item, index) => {
      item.priority = updatedConditions.length - index;
    });

    updateConditionPriority(updatedConditionsIdAndPriority, [...updatedConditions, ...defaultCondition], conditions);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Box sx={{ flexBasis: 96, h: "100%", py: 7, borderRight: "solid 1px #E4E8EE" }}>
        <Flex justifyContent={"space-between"} flexDirection="column" pr={4}>
          <Flex justifyContent={"space-between"} w="100%" flexDirection={"column"} gap="2">
            <Flex alignItems={"center"}>
              <Typography variant="h2-r-medium" pr={2}>
                {t("Conditions")}
              </Typography>
              <ToolTip
                placement="bottom"
                label={t(
                  "Conditions allow you to segment your shoppers and display personalized checkout pages based on customer properties and actions.",
                )}
                maxWidth={"285px"}
              >
                <span>
                  <Icons.Question color={"neutrals.700"} />
                </span>
              </ToolTip>
            </Flex>
            <Flex my="auto">
              <Typography
                as="a"
                variant="small"
                color="link.color"
                fontWeight={500}
                className={APP_PLATFORM === Platform.Wix ? "link" : " "}
                cursor="pointer"
                onClick={() => {
                  const link =
                    APP_PLATFORM === Platform.Shopify
                      ? "https://help.reconvert.io/en/article/how-do-conditions-work-12yqfsv/reader/?bust=1706881668418"
                      : "https://help.reconvert.io/en/article/how-do-conditions-work-wix-unpozd/reader/?bust=1716369017202";

                  dispatch(
                    toggleHelpModal({
                      link: link,
                      title: t("How do conditions work?").toString(),
                    }),
                  );
                }}
              >
                {t("How do conditions work?")}
              </Typography>
            </Flex>
          </Flex>
        </Flex>
        <Box pr={4} mb="16px">
          <Divider orientation="horizontal" my="16px" />
          <Button variant="secondary" w="100%" onClick={() => preventAction(() => handleAddConditionClick())}>
            <Typography variant="subheading2" color="neutrals.900" fontWeight={500} lineHeight="20px" mr="8px">
              {t("Add Condition")}
            </Typography>
            <Icons.PlusCircle cursor="pointer" height={"16px"} width={"16px"} />
          </Button>
        </Box>
        <Box
          mt={4}
          textAlign="center"
          overflowY={"auto"}
          height={`calc(100vh - ${APP_PLATFORM === Platform.Wix ? "240px" : "200px"})`}
          ref={conditionsContainerRef}
        >
          {overview === Overview.ADD_CONDITION_VIEW && <SkeletonComponent />}

          {isConditionDataLoading ? (
            <Spinner variant={"ring"} size={"lg"} mt={20} />
          ) : (
            <>
              {!conditions || conditions.length === 0 ? (
                <ConditionCard
                  title="Default condition"
                  description="Horem ipsum dolor sit amet, consectetur adipiscing elit."
                  isDisabled={false}
                  isSelected={false}
                  isConditionOn={false}
                  mode="default"
                  p={0}
                />
              ) : (
                <>
                  <Droppable droppableId="conditions">
                    {(provided, snapshot) => (
                      <Box {...provided.droppableProps} ref={provided.innerRef}>
                        {conditions.map((con, index) => {
                          return (
                            !con.isDefault && (
                              <Draggable key={con.id.toString()} draggableId={con.id.toString()} index={index}>
                                {(provided, snapshot) => (
                                  <Box ref={provided.innerRef} {...provided.draggableProps} key={con?.id}>
                                    <ConditionCard
                                      conditionId={con?.id}
                                      title={con?.name}
                                      description={
                                        con?.isDefault
                                          ? t(
                                              "ReConvert displays the default condition when customers do not meet any other condition",
                                            )
                                          : con?.storeName
                                      }
                                      isDisabled={!con?.status}
                                      isConditionOn={con?.status || false}
                                      isSelected={selectedConditionId === con?.id}
                                      onClick={() => preventAction(() => setSelectedConditionId(con?.id))}
                                      mode={con?.rule ? "collapsed" : "default"}
                                      actions={[]}
                                      p={2}
                                      toggleCondition={updateConditionStatus}
                                      condition={con}
                                      id={index === conditions.length - 1 ? "defaultCondition" : ""}
                                      dragHandleProps={provided.dragHandleProps}
                                      snapshot={snapshot}
                                    >
                                      <ConditionTextContainer
                                        con={con}
                                        mouseRef={mouseRef}
                                        formatCurrency={formatCurrency}
                                      />
                                    </ConditionCard>
                                  </Box>
                                )}
                              </Draggable>
                            )
                          );
                        })}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                  <>
                    {defaultCondition.map((con, index) => {
                      return (
                        <ConditionCard
                          key={con?.id}
                          conditionId={con?.id}
                          title={con?.name}
                          description={
                            con?.isDefault
                              ? t(
                                  "ReConvert displays the default condition when customers do not meet any other condition",
                                )
                              : con?.storeName
                          }
                          isDisabled={!con?.status}
                          isConditionOn={con?.status || false}
                          isSelected={selectedConditionId === con?.id}
                          onClick={e => preventAction(() => setSelectedConditionId(con?.id))}
                          mode={con?.rule ? "collapsed" : "default"}
                          actions={[]}
                          p={2}
                          toggleCondition={updateConditionStatus}
                          condition={con}
                          id={"defaultCondition"}
                        ></ConditionCard>
                      );
                    })}
                  </>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </DragDropContext>
  );
};

const ConditionTextContainer = ({
  con,
  mouseRef,
  formatCurrency,
}: {
  con: Condition;
  mouseRef: MutableRefObject<any>;
  formatCurrency: (value: number) => string;
}) => {
  const conditionArray = con?.rule?.any;
  const flattenedCondition = conditionArray?.flatMap((item: any) => item.all);
  const flattenedConditionLength = flattenedCondition?.length;
  const [showedCondition, setShowedCondition] = useState(3);

  return (
    <>
      {flattenedCondition?.slice(0, showedCondition).map((item: any, index: number) => {
        return (
          <Box key={item?.id}>
            {index > 0 && item?.conditionType === ConditionType.OR && <OrSeprator />}
            {index > 0 && item?.conditionType === ConditionType.AND && <AndSeprator />}
            <ConditionText con={item} mouseRef={mouseRef} formatCurrency={formatCurrency} />
          </Box>
        );
      })}
      {flattenedConditionLength > showedCondition && (
        <Flex m={2}>
          <Typography
            fontStyle={"italic"}
            variant="body"
            onClick={e => {
              e.stopPropagation();
              setShowedCondition(flattenedConditionLength);
            }}
          >
            + {flattenedConditionLength - showedCondition} more conditions
          </Typography>
        </Flex>
      )}
    </>
  );
};

const ConditionText = ({
  con,
  mouseRef,
  formatCurrency,
}: {
  con: ConditionRule;
  mouseRef: MutableRefObject<any>;
  formatCurrency: (value: number) => string;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const renderConditionInfo = (conditionRule: ConditionRule) => {
    switch (conditionRule?.fact) {
      case ConditionFact.SPECIFIC_PRODUCT:
        return (
          <>
            {conditionRule?.extraFields?.productIds?.length}{" "}
            {`product${conditionRule?.extraFields?.productIds?.length > 1 ? "s" : ""}`}{" "}
          </>
        );

      case ConditionFact.PRODUCT_COLLECTION:
        return (
          <>
            {conditionRule?.value?.length}{" "}
            {APP_PLATFORM === Platform.Shopify
              ? `collection${conditionRule?.value?.length > 1 ? "s" : ""}`
              : `${conditionRule?.value?.length === 1 ? "category" : "categories"}`}
          </>
        );

      default:
        break;
    }
  };

  return (
    <>
      <Flex gap="12px">
        <Divider orientation="vertical" height={"auto"} borderLeft={"2px solid"} borderLeftColor={"divider.2"} />
        <Typography variant="body">
          <span
            dangerouslySetInnerHTML={{
              __html: getConditionText(con, formatCurrency, true, APP_PLATFORM as any) || "",
            }}
          ></span>{" "}
          <ToolTip
            placement="right"
            isOpen={isOpen}
            label={<ConditionTooltip conditionRule={con} setIsOpen={setIsOpen} mouseRef={mouseRef} />}
            pointerEvents={"all"}
          >
            <Text
              as="u"
              onMouseEnter={() => {
                mouseRef.current = false;
                setIsOpen(true);
              }}
              onMouseLeave={() => {
                setTimeout(() => {
                  if (!mouseRef.current) {
                    setIsOpen(false);
                  }
                }, 500);
              }}
            >
              {renderConditionInfo(con)}
            </Text>
          </ToolTip>
        </Typography>
      </Flex>
    </>
  );
};

export default Conditions;
