import React, { useMemo } from "react";
import { Box, Center, Divider, Flex, Hide, Show, Spinner, useMediaQuery } from "@chakra-ui/react";
import { TFunction } from "i18next";
import { useAppSelector } from "../../../app/hooks";
import { selectAppState } from "../../../app/slice/appSlice";
import { AnalyticsRecord, selectVariantAnalysis } from "../../../app/slice/analyticsSlice";
import { useTranslation } from "react-i18next";
import AnalyticsRow from "./AnalyticsRow";
import { Icons, Typography, useCurrencyFormatter } from "@reconvert/react-ui-component";
import { PageType } from "@reconvert/reconvert-utils";

interface VariantAnalyticsRow {
  name: string;
  currentValue: string;
  diffInPercentage: string;
}

export const prepareVariantAnalyticsRow = (
  t: TFunction<"translation", undefined>,
  activePage: PageType,
  variantsAnalyticDetail?: AnalyticsRecord,
): VariantAnalyticsRow[] =>
  activePage === PageType.CHECKOUT ||
    activePage === PageType.THANK_YOU_PAGE ||
    activePage === PageType.ORDER_STATUS_PAGE
    ? [
      {
        name: t("Revenue"),
        currentValue: variantsAnalyticDetail?.revenue?.toString() ?? "0",
        diffInPercentage: variantsAnalyticDetail?.revenueDiffPercentage?.toString() ?? "0",
      },
      {
        name: t("Impressions"),
        currentValue: variantsAnalyticDetail?.impressions?.toString() ?? "0",
        diffInPercentage: variantsAnalyticDetail?.impressionsDiffPercentage?.toString() ?? "0%",
      },
      {
        name: t("Clicks"),
        currentValue: variantsAnalyticDetail?.clicks?.toString() ?? "0",
        diffInPercentage: variantsAnalyticDetail?.clicksDiffPercentage?.toString() ?? "0%",
      },
      {
        name: t("Conversions"),
        currentValue: variantsAnalyticDetail?.conversions?.toString() ?? "0",
        diffInPercentage: variantsAnalyticDetail?.conversionsDiffPercentage?.toString() ?? "0%",
      },
      {
        name: t("Conversion rate"),
        currentValue: (variantsAnalyticDetail?.cvr?.toString() ?? "0") + "%" ,
        diffInPercentage: variantsAnalyticDetail?.cvrDiffPercentage?.toString() ?? "0%",
      },
    ]
    : [
      {
        name: t("Revenue"),
        currentValue: variantsAnalyticDetail?.revenue?.toString() ?? "0",
        diffInPercentage: variantsAnalyticDetail?.revenueDiffPercentage?.toString() ?? "0",
      },
      {
        name: t("Impressions"),
        currentValue: variantsAnalyticDetail?.impressions?.toString() ?? "0",
        diffInPercentage: variantsAnalyticDetail?.impressionsDiffPercentage?.toString() ?? "0%",
      },
      {
        name: t("Conversions"),
        currentValue: variantsAnalyticDetail?.conversions?.toString() ?? "0",
        diffInPercentage: variantsAnalyticDetail?.conversionsDiffPercentage?.toString() ?? "0%",
      },
      {
        name: t("Declined offers"),
        currentValue: variantsAnalyticDetail?.declined?.toString() ?? "0",
        diffInPercentage: variantsAnalyticDetail?.declinedDiffPercentage?.toString() ?? "0%",
      },
      {
        name: t("Conversion rate"),
        currentValue: (variantsAnalyticDetail?.cvr?.toString() ?? "0") + "%",
        diffInPercentage: variantsAnalyticDetail?.cvrDiffPercentage?.toString() ?? "0%",
      },
    ];

const AnalyticView = ({ slotId, variantStatus }: { slotId: string; variantStatus: boolean }) => {
  const [isLargerThan1464] = useMediaQuery("(min-width: 1464px)");
  const [isLargerThan1573] = useMediaQuery("(min-width: 1573px)");
  const { t } = useTranslation();
  const {
    view: { isAnalyticView },
    activePage,
    storeInfo
  } = useAppSelector(selectAppState);
  const { variantsAnalytics, loading, error } = useAppSelector(selectVariantAnalysis);
  const { currencyFormatter } = useCurrencyFormatter();

  const analyticsDetails = useMemo(() => {
    const variantsAnalyticDetail = variantsAnalytics.filter(
      (analytics: AnalyticsRecord) => analytics.slotId === slotId,
    )[0];

    if (variantsAnalyticDetail) {
      return prepareVariantAnalyticsRow(t, activePage!, variantsAnalyticDetail);
    } else {
      return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantsAnalytics, slotId, activePage]);

  let impressionValue = Number.parseFloat(analyticsDetails?.find((e) => e.name === "Impressions")?.currentValue || "0")

  return (
    <Box>
      {isAnalyticView ? (
        <>
          <Divider mt={3} />
          {loading ? (
            <Center pt={2} pb={2}>
              <Spinner variant={"ring"} size={"sm"} />
            </Center>
          ) : (
            <>
              {!error && analyticsDetails && impressionValue > 0 ? (
                <Flex
                  py={3}
                  gap={6}
                  width={"100%"}>
                  <Flex
                    pr={1}
                    justifyContent={"space-between"}
                    width={"50%"}
                    pt={2}
                    pb={2}
                  >
                    {analyticsDetails?.slice(1)?.map((val: VariantAnalyticsRow, index: number) => (
                      <>
                        <AnalyticsRow
                          key={index}
                          name={val?.name}
                          value={val?.currentValue}
                          change={val?.diffInPercentage}
                          variantStatus={variantStatus}
                          iconSize={"sm"}
                          layout="newLayout"
                        />
                      </>
                    ))}
                  </Flex>
                  <Flex py={"8px"} borderRadius={"8px"} backgroundColor={"#F4F6F8"} direction="column" width={"50%"} justifyContent={"center"} alignItems={"center"} >
                    <Typography variant="subheading2" size={"16px"} fontWeight={500}>
                      {t("Revenue")}
                    </Typography>
                    {analyticsDetails?.[0]?.currentValue ? (
                      <>
                        <Flex justifyContent={"center"} alignItems={"center"} gap={"6px"}>
                          <Typography size={"18px"} fontWeight={500} variant="subheading2"  >
                            {currencyFormatter(+analyticsDetails?.[0]?.currentValue, storeInfo?.currency!)}
                          </Typography>
                          <Typography
                            color={
                              variantStatus
                                ? analyticsDetails?.[0]?.diffInPercentage.startsWith("-")
                                  ? `error.800`
                                  : `success.800`
                                : "disable.color"
                            }
                            variant="xsmall"
                          >
                            {String(analyticsDetails?.[0]?.diffInPercentage)?.startsWith("-") ? analyticsDetails?.[0]?.diffInPercentage : `+${analyticsDetails?.[0]?.diffInPercentage}`}
                          </Typography>
                        </Flex>
                      </>
                    ) : (
                      <Typography variant="xsmall" fontWeight={500}>
                        -
                      </Typography>
                    )}
                  </Flex>
                </Flex>
              ) : (
                <Typography
                  my={3}
                  textAlign="center"
                  variant="subheading2"
                  color={error ? "error.700" : variantStatus ? "neutrals.600" : "disable.color"}
                >
                  {error
                    ? t("It seems we're having trouble fetching the Analytics information you requested")
                    : t("Your analytics will be displayed here as soon as data is available")}
                </Typography>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AnalyticView;
