import { Flex, Input, Select } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Icons, Typography } from "@reconvert/react-ui-component";
import React, { useCallback } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import ProductSelector from "../ConditionCreationSidebar/ProductSelector";
import ProductTagSelector from "../ConditionCreationSidebar/ProductTagSelector";
import ProductSkuSelector from "../ConditionCreationSidebar/ProductSkuSelector";
import { APP_PLATFORM } from "../../../../config";
import { Platform } from "../../../../models";
import ProductTextSelector from "../ConditionCreationSidebar/ProductTextSelector";
import { selectAppState } from "../../../../app/slice/appSlice";
import { getConditionsMetaData } from "../../../../utils/conditionsMetaData";
import {
  LAUNCH_DARKLY_AND_OR_CONDITIONS_IN_FLOW_BUILDER,
  LAUNCH_DARKLY_CUSTOMER_SEGMENTS,
  LAUNCH_DARKLY_NEW_CONDITIONS,
  LAUNCH_DARKLY_PRODUCT_COLLECTION_CONDITION,
} from "../../../../utils/constant";
import { ConditionFact, PageType } from "@reconvert/reconvert-utils";
import { ConditonData } from "../../../../hooks/useAndOrGrouping";
import CartOrderSelector from "../ConditionCreationSidebar/CartOrderSelector";
import { useAppSelector } from "../../../../app/hooks";

const StyledSelect = styled(Select)`
  border: 1px solid #6a7381;
  transition: border-color 0.3s;
  height: 36px;

  &:hover {
    border: 2px solid #6a7381;
  }
`;

const AndOrConditionBox = ({
  conditionData,
  conditionsMetaData,
  handleDeleteCondition,
  updateConditionData,
  allCondition,
  conditionName,
  updateConditionName,
}: {
  conditionData: any;
  conditionsMetaData: any;
  handleDeleteCondition: () => void;
  updateConditionData: (condition: ConditonData) => void;
  allCondition: ConditonData[];
  conditionName: string;
  updateConditionName: (name: string) => void;
}) => {
  const { t } = useTranslation();

  const { activePage, storeInfo, ldFlags } = useAppSelector(selectAppState) as {
    activePage: any;
    storeInfo: any;
    ldFlags: any;
  };
  const isAndOrConditionsInFlowBuilder = ldFlags?.[LAUNCH_DARKLY_AND_OR_CONDITIONS_IN_FLOW_BUILDER];

  const renderConditionValue = useCallback(
    (value: any) => {
      switch (value) {
        case ConditionFact.SPECIFIC_PRODUCT:
          return (
            <ProductSelector
              conditionFact={ConditionFact.SPECIFIC_PRODUCT}
              value={{ productIds: conditionData?.productIds || [], variantIds: conditionData?.variantIds || [] }}
              setValue={(data: { productIds: string[]; variantIds: string[] }) =>
                updateConditionData({
                  ...conditionData,
                  ...data,
                  id: conditionData.id,
                })
              }
            />
          );

        case ConditionFact.PRODUCT_COLLECTION:
          return (
            <ProductSelector
              conditionFact={ConditionFact.PRODUCT_COLLECTION}
              value={{ productCollectionIds: conditionData?.productCollectionIds, variantIds: [], productIds: [] }}
              setValue={(data: { productCollectionIds?: { id: string; name: string }[] }) =>
                updateConditionData({
                  ...conditionData,
                  productCollectionIds: data?.productCollectionIds,
                  id: conditionData.id,
                })
              }
            />
          );

        case ConditionFact.PRODUCT_TAGS:
          return (
            <ProductTagSelector
              conditionFact={ConditionFact.PRODUCT_TAGS}
              label="Tags"
              itemsValue={conditionData?.items || []}
              setItemsValue={(data: { items: string | string[]; info: string | string[] }) => {
                updateConditionData({
                  ...conditionData,
                  items: data.items,
                  itemsInfo: data.info,
                  id: conditionData.id,
                });
              }}
              isOptionCreatable
            />
          );

        case ConditionFact.CUSTOMER_LANGUAGE:
          return (
            <ProductTagSelector
              conditionFact={ConditionFact.CUSTOMER_LANGUAGE}
              label="Language"
              itemsValue={conditionData?.items || []}
              setItemsValue={(data: { items: string | string[]; info: string | string[] }) => {
                updateConditionData({
                  ...conditionData,
                  items: data.items,
                  itemsInfo: data.info,
                  id: conditionData.id,
                });
              }}
            />
          );

        case ConditionFact.CUSTOMER_COUNTRY:
          return (
            <ProductTagSelector
              conditionFact={ConditionFact.CUSTOMER_COUNTRY}
              label="Customer country"
              itemsValue={conditionData?.items || []}
              setItemsValue={(data: { items: string | string[]; info: string | string[] }) => {
                updateConditionData({
                  ...conditionData,
                  items: data.items,
                  itemsInfo: data.info,
                  id: conditionData.id,
                });
              }}
            />
          );

        case ConditionFact.PRODUCT_SALES_CHANNEL:
          return (
            <ProductTagSelector
              conditionFact={ConditionFact.PRODUCT_SALES_CHANNEL}
              label="Product sales channel"
              itemsValue={conditionData?.items || []}
              setItemsValue={(data: { items: string | string[]; info: string | string[] }) => {
                updateConditionData({
                  ...conditionData,
                  items: data.items,
                  itemsInfo: data.info,
                  id: conditionData.id,
                });
              }}
            />
          );

        case ConditionFact.PRODUCT_TITLE:
          return (
            <ProductTextSelector
              titleText="Title"
              value={conditionData?.value || ""}
              setTitleValue={(data: { value: string }) =>
                updateConditionData({
                  ...conditionData,
                  ...data,
                  id: conditionData.id,
                })
              }
            />
          );

        case ConditionFact.DISCOUNT_CODE:
          return (
            <ProductTextSelector
              titleText="Discount code"
              value={conditionData?.value || ""}
              setTitleValue={(data: { value: string }) =>
                updateConditionData({
                  ...conditionData,
                  ...data,
                  id: conditionData.id,
                })
              }
            />
          );

        case ConditionFact.PRODUCT_VARIANT_NAME:
          return (
            <ProductTextSelector
              titleText="Title"
              value={conditionData?.value || ""}
              setTitleValue={(data: { value: string }) =>
                updateConditionData({
                  ...conditionData,
                  ...data,
                  id: conditionData.id,
                })
              }
            />
          );

        case ConditionFact.PRODUCT_SKU:
          return (
            <ProductSkuSelector
              sku={conditionData?.value || ""}
              setSkuValue={(data: { value: string }) =>
                updateConditionData({
                  ...conditionData,
                  ...data,
                  id: conditionData.id,
                })
              }
            />
          );

        case ConditionFact.CART_OR_ORDER_VALUE:
          return (
            <CartOrderSelector
              conditionData={conditionData}
              updateConditionData={(data: { value?: string; fromValue?: string; toValue?: string }) =>
                updateConditionData({
                  ...conditionData,
                  ...data,
                  id: conditionData.id,
                })
              }
            />
          );

        case ConditionFact.CUSTOMER_SEGMENTS:
          return (
            <ProductTagSelector
              conditionFact={ConditionFact.CUSTOMER_SEGMENTS}
              label="Customer Segments"
              itemsValue={conditionData?.items || []}
              setItemsValue={(data: { items: string | string[]; info: string | string[] }) => {
                updateConditionData({
                  ...conditionData,
                  items: data.items,
                  itemsInfo: data.info,
                  id: conditionData.id,
                });
              }}
            />
          );

        case ConditionFact.PREV_PAGE_OFFER:
          if (conditionData.thirdOperatorOption === "ONEOFSELECTED") {
            return (
              <ProductTagSelector
                conditionData={conditionData}
                conditionFact={ConditionFact.PREV_PAGE_OFFER}
                label="Condition list"
                itemsValue={conditionData?.items || []}
                setItemsValue={(data: { items: string | string[]; info: string | string[] }) => {
                  updateConditionData({
                    ...conditionData,
                    items: data.items,
                    itemsInfo: data.info,
                    id: conditionData.id,
                  });
                }}
              />
            );
          } else {
            return <></>;
          }

        default:
          break;
      }
    },
    [conditionData, updateConditionData],
  );

  const getConditionOptions = useCallback(
    (t: TFunction<"translation", undefined>) => {
      const options = [
        { value: conditionsMetaData.SPECIFIC_PRODUCT.value, label: t("Specific product") },
        // { value: conditionsMetaData.PRODUCT_COLLECTION.value, label: t("Product collection") },
        // { value: conditionsMetaData.PRODUCT_VARIANT_NAME.value, label: t("Variant name") },
        // { value: conditionsMetaData.DISCOUNT_CODE.value, label: t("Discount code") },
        // { value: conditionsMetaData.CUSTOMER_LANGUAGE.value, label: t("Language") },
        // { value: conditionsMetaData.CUSTOMER_COUNTRY.value, label: t("Customer country") },
      ];

      if (APP_PLATFORM === Platform.Shopify) {
        options.push({ value: conditionsMetaData.PRODUCT_TITLE.value, label: t("Product title") });
        if (ldFlags && ldFlags[LAUNCH_DARKLY_PRODUCT_COLLECTION_CONDITION]) {
          options.push({ value: conditionsMetaData.PRODUCT_COLLECTION.value, label: t("Product collection") });
        }

        options.push({ value: conditionsMetaData.PRODUCT_SKU.value, label: t("Product SKU") });
        options.push({ value: conditionsMetaData.CART_OR_ORDER_VALUE.value, label: t("Cart/Order value") });
        options.push({ value: conditionsMetaData.PRODUCT_TAGS.value, label: t("Product tags") });
        options.push({ value: conditionsMetaData.PRODUCT_VARIANT_NAME.value, label: t("Variant name") });
        options.push({ value: conditionsMetaData.DISCOUNT_CODE.value, label: t("Discount code") });
        options.push({ value: conditionsMetaData.CUSTOMER_LANGUAGE.value, label: t("Language") });
        options.push({ value: conditionsMetaData.CUSTOMER_COUNTRY.value, label: t("Customer country") });

        if (ldFlags && ldFlags[LAUNCH_DARKLY_CUSTOMER_SEGMENTS]) {
          options.push({ value: conditionsMetaData.CUSTOMER_SEGMENTS.value, label: t("Customer segments") });
        }
        // options.push({ value: conditionsMetaData.PRODUCT_SALES_CHANNEL.value, label: t("Product sales channel") });

        //Previous Offer
        if (activePage !== PageType.CHECKOUT) {
          if (activePage === PageType.POST_PURCHASE_PAGE1) {
            if (storeInfo?.isShopifyPlusMerchant) {
              options.push({ value: conditionsMetaData.PREV_PAGE_OFFER.value, label: t("Previous offer") });
            }
          } else {
            options.push({ value: conditionsMetaData.PREV_PAGE_OFFER.value, label: t("Previous offer") });
          }
        }
      } else {
        options.push({ value: conditionsMetaData.PRODUCT_TITLE.value, label: t("Product title") });
        if (ldFlags && ldFlags[LAUNCH_DARKLY_PRODUCT_COLLECTION_CONDITION]) {
          options.push({ value: conditionsMetaData.PRODUCT_COLLECTION.value, label: t("Product category") });
        }

        options.push({ value: conditionsMetaData.PRODUCT_SKU.value, label: t("Product SKU") });
        options.push({ value: conditionsMetaData.CART_OR_ORDER_VALUE.value, label: t("Cart/Order value") });
        if (ldFlags && ldFlags[LAUNCH_DARKLY_NEW_CONDITIONS]) {
          options.push({ value: conditionsMetaData.PRODUCT_VARIANT_NAME.value, label: t("Variant name") });
          options.push({ value: conditionsMetaData.DISCOUNT_CODE.value, label: t("Discount code") });
        }
      }

      return options;
    },
    [activePage, storeInfo?.isShopifyPlusMerchant, ldFlags],
  );

  const updateOperatorValue = (e: any) => {
    const operatorValue = getConditionsMetaData(activePage!, storeInfo?.isShopifyPlusMerchant!, e.target.value);
    const conditionKey = conditionData.fact as ConditionFact;

    updateConditionData({
      ...conditionData,
      id: conditionData.id,
      condition: e.target.value,
      operator: operatorValue[conditionKey]?.operatorOptions[0]?.value,
    });
  };

  return (
    <>
      <Flex pt={4} flexDirection={"column"}>
        <Flex gap={"8px"} flexDirection={"column"}>
          {!isAndOrConditionsInFlowBuilder && (
            <Flex gap={1} flexDirection={"column"}>
              <Typography color={"#6A7381"} width={"max-content"} bg={"var(--Natural-100, #F9FAFC)"} variant="small">
                {t("Condition name")}
              </Typography>
              <Input
                value={conditionName}
                onChange={e => updateConditionName(e.target.value)}
                placeholder={t("My new condition")}
                _hover={{
                  paddingLeft: "12px",
                }}
              />
            </Flex>
          )}
          <Flex gap={1} flexDirection={"column"}>
            <Typography color={"#6A7381"} width={"max-content"} bg={"var(--Natural-100, #F9FAFC)"} variant="small">
              {t("Condition")}
            </Typography>
            <StyledSelect
              value={conditionData.fact}
              onChange={(e: any) => {
                const conditionKey = e.target.value as ConditionFact;

                const operatorValue = getConditionsMetaData(
                  activePage!,
                  storeInfo?.isShopifyPlusMerchant!,
                  conditionsMetaData[e.target.value]?.options[0]?.value,
                );

                updateConditionData({
                  ...conditionData,
                  id: conditionData.id,
                  fact: e.target.value,
                  condition: operatorValue[conditionKey]?.options[0]?.value || "",
                  operator: operatorValue[conditionKey]?.operatorOptions[0]?.value || "",
                  value: "",
                  items: [],
                  productIds: [],
                  productCollectionIds: [],
                  variantIds: [],
                });
              }}
            >
              {getConditionOptions(t).map(item => {
                return (
                  <option key={item.value} value={item.value}>
                    {item?.label}
                  </option>
                );
              })}
            </StyledSelect>
          </Flex>
        </Flex>

        {conditionData.fact && (
          <>
            {conditionsMetaData[conditionData?.fact]?.options.length > 0 && (
              <Flex mt="2" gap={1} flexDirection={"column"}>
                <Typography color={"#6A7381"} bg={"var(--Natural-100, #F9FAFC)"} variant="small" width={"max-content"}>
                  {t("Operator 1")}
                </Typography>
                <StyledSelect
                  value={conditionData.condition}
                  onChange={(e: any) => {
                    updateOperatorValue(e);
                  }}
                >
                  {conditionsMetaData[conditionData.fact as keyof typeof conditionsMetaData].options?.map((o: any) => {
                    return <option value={o.value}>{t(o.label)}</option>;
                  })}
                </StyledSelect>
              </Flex>
            )}

            <Flex mt="8px" gap={1} flexDirection={"column"}>
              <Typography color={"#6A7381"} bg={"var(--Natural-100, #F9FAFC)"} variant="small" width={"max-content"}>
                {t("Operator 2")}
              </Typography>
              <StyledSelect
                value={conditionData?.operator}
                onChange={(e: any) => {
                  updateConditionData({
                    ...conditionData,
                    operator: e.target.value,
                    id: conditionData.id,
                  });
                }}
              >
                {conditionsMetaData[conditionData.fact as keyof typeof conditionsMetaData]?.operatorOptions?.map(
                  (o: any) => {
                    return <option value={o.value}>{t(o.label)}</option>;
                  },
                )}
              </StyledSelect>
            </Flex>

            {conditionData.fact && (
              <>
                {conditionsMetaData[conditionData?.fact]?.thirdOperatorOptions ? (
                  <Flex mt="2" gap={1} flexDirection={"column"}>
                    <Typography
                      color={"#6A7381"}
                      bg={"var(--Natural-100, #F9FAFC)"}
                      variant="small"
                      width={"max-content"}
                    >
                      {t("Operator 3")}
                    </Typography>
                    <StyledSelect
                      value={conditionData?.thirdOperatorOption ?? ""}
                      onChange={(e: any) => {
                        updateConditionData({
                          ...conditionData,
                          thirdOperatorOption: e.target.value,
                          id: conditionData.id,
                        });
                      }}
                    >
                      {conditionsMetaData[conditionData.fact as keyof typeof conditionsMetaData]
                        .thirdOperatorOptions ? (
                        conditionsMetaData[
                          conditionData.fact as keyof typeof conditionsMetaData
                        ]?.thirdOperatorOptions?.map((o: any) => {
                          return <option value={o.value}>{t(o.label)}</option>;
                        })
                      ) : (
                        <></>
                      )}
                    </StyledSelect>
                  </Flex>
                ) : (
                  <></>
                )}
              </>
            )}

            {renderConditionValue(conditionData.fact as keyof typeof conditionsMetaData)}

            {allCondition?.length > 1 && (
              <Flex mt={4} gap="10px" justifyContent={"end"} alignItems={"center"}>
                <Icons.TrashSimple height={"16px"} width={"16px"} onClick={handleDeleteCondition} cursor={"pointer"} />
              </Flex>
            )}
          </>
        )}
      </Flex>
    </>
  );
};

export default React.memo(AndOrConditionBox);
