import { Box, Divider, Flex } from "@chakra-ui/react";
import { Typography } from "@reconvert/react-ui-component";

const OrSeprator = () => {
  return (
    <Flex gap="8px" alignItems={"center"} my={4}>
      <Box padding={"8px 16px"} borderRadius={"16px"} background={"#D8F2F8"}>
        <Typography variant="tiny">OR</Typography>
      </Box>
      <Divider borderBottomWidth={"2px"} />
    </Flex>
  );
};

export default OrSeprator;
