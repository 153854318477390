import { Box, Flex, Input, InputGroup, InputLeftElement, Spinner } from "@chakra-ui/react";
import { Icons, Typography } from "@reconvert/react-ui-component";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useProductSelector from "../../../../hooks/useProductSelector";
import SearchProductModal from "./AddConditionView/SearchProductModal";
import { ConditionFact } from "@reconvert/reconvert-utils";
import { APP_PLATFORM } from "../../../../config";
import { Platform } from "../../../../models";

interface ProductSelectorProps {
  value: { productIds: string[]; variantIds: string[]; productCollectionIds?: { id: string; name: string }[] };
  setValue: (val: {
    productIds: string[];
    variantIds: string[];
    productCollectionIds?: { id: string; name: string }[];
  }) => void;
  conditionFact?: string;
}

const ProductSelector = ({ value, setValue, conditionFact }: ProductSelectorProps) => {
  const { t } = useTranslation();

  const [displayedProducts, setDisplayedProducts] = useState(4);

  const [isSearchProductModalShow, setIsSearchProductModalShow] = useState<boolean>(false);
  const {
    selectedProductIdObj,
    isProductsLoading,
    selectedProducts,
    selectedProductCollections,
    updateSelectedProductIdObj,
    handleRemoveProduct,
  } = useProductSelector({
    value,
    setValue,
    conditionFact: conditionFact || ConditionFact.SPECIFIC_PRODUCT,
  });

  const handleLoadMore = useCallback(() => {
    setDisplayedProducts(displayedProducts + 4);
  }, [displayedProducts]);

  const moreProductsText = useMemo(() => {
    const text = conditionFact === ConditionFact.SPECIFIC_PRODUCT ? `product` : `collection`;
    const totalSelectedProducts =
      conditionFact === ConditionFact.SPECIFIC_PRODUCT
        ? [...selectedProducts]?.length
        : [...(selectedProductCollections || [])]?.length;

    if (totalSelectedProducts < 4) {
      return "";
    }

    const remainingProducts = Math.max(0, totalSelectedProducts - displayedProducts);

    if (remainingProducts === 0) {
      return "";
    }

    if (remainingProducts === 1) {
      return t(`+ 1 more ${text}`);
    }

    return `+ ${remainingProducts} ${t(`more ${text}s`)}`;
  }, [conditionFact, displayedProducts, selectedProductCollections, selectedProducts, t]);

  const productList = useMemo(() => {
    return conditionFact === ConditionFact.SPECIFIC_PRODUCT
      ? selectedProducts?.slice(0, displayedProducts)
      : selectedProductCollections?.slice(0, displayedProducts);
  }, [conditionFact, displayedProducts, selectedProductCollections, selectedProducts]);

  return (
    <>
      <Flex mt="8px" gap={1} flexDirection={"column"}>
        <InputGroup
          onClick={() => {
            setIsSearchProductModalShow(true);
          }}
        >
          <InputLeftElement>
            <Icons.Search color="#6A7381" />
          </InputLeftElement>
          <Input
            cursor={"pointer"}
            placeholder={
              conditionFact === ConditionFact.SPECIFIC_PRODUCT
                ? t("Search for products")
                : APP_PLATFORM === Platform.Shopify
                  ? t("Search for product collections")
                  : t("Search for product categories")
            }
            _hover={{
              border: "2px solid #6A7381",
            }}
            _focus={{
              borderColor: "#6A7381",
            }}
          />
        </InputGroup>
      </Flex>

      {isProductsLoading ? (
        <Flex justifyContent={"center"} mt="12px">
          <Spinner />
        </Flex>
      ) : (
        productList &&
        productList?.length > 0 && (
          <Flex
            width={"100%"}
            borderRadius={"4px"}
            borderLeft={"1px solid"}
            borderRight={"1px solid"}
            borderBottom={"1px solid"}
            borderColor={"neutrals.600"}
            flexDirection={"column"}
          >
            <Box maxHeight={"300px"} overflowY={"auto"} mr={"4px"}>
              {productList?.map((item: any, key: any) => (
                <Flex px="24px" py="14px" key={"item-" + key} justifyContent={"space-between"} alignItems={"center"}>
                  <Flex gap="12px">
                    {conditionFact === ConditionFact.SPECIFIC_PRODUCT && (
                      <>
                        {item?.images?.length > 0 ? (
                          <img src={item?.images[0]} alt="" height={"36px"} width={"36px"} />
                        ) : (
                          <Icons.ImagePreview w="36px" h="36px" />
                        )}
                      </>
                    )}

                    <Box>
                      <Typography lineHeight={"20px"} variant="subheading2" overflowWrap={"anywhere"}>
                        {item?.title ?? item?.name}
                      </Typography>
                    </Box>
                  </Flex>
                  <Icons.CloseIcon
                    height={"16px"}
                    width={"16px"}
                    cursor={"pointer"}
                    onClick={() => {
                      handleRemoveProduct(item?.id);
                    }}
                  />
                </Flex>
              ))}
            </Box>
          </Flex>
        )
      )}
      {moreProductsText && (
        <Flex mt={"8px"} justifyContent={"end"}>
          <Typography
            onClick={handleLoadMore}
            cursor={"pointer"}
            variant="body"
            color={"#2F73DA"}
            lineHeight={"16px"}
            fontWeight={500}
          >
            {moreProductsText}
          </Typography>
        </Flex>
      )}
      {isSearchProductModalShow && (
        <SearchProductModal
          value={
            conditionFact === ConditionFact.SPECIFIC_PRODUCT
              ? selectedProductIdObj
              : selectedProductCollections?.reduce((acc, item) => {
                  acc[item.id] = acc[item.id] || [];
                  acc[item.id].push(item.name);
                  return acc;
                }, {} as any)
          }
          setValue={data => {
            updateSelectedProductIdObj(data);
            setIsSearchProductModalShow(false);
          }}
          handleOnClose={() => setIsSearchProductModalShow(false)}
          conditionFact={conditionFact}
        />
      )}
    </>
  );
};

export default ProductSelector;
