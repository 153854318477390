import { Box, Center, Divider, Flex, IconButton, Input, Spinner } from "@chakra-ui/react";
import { AlertDialogModal, Icons, Typography, useToast } from "@reconvert/react-ui-component";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../app/hooks";
import { selectConditionsState } from "../../../../../app/slice/conditionSlice";
import useConditions from "../../../../../hooks/useConditions";
import ConditionTitle from "../ConditionTitle";
import useUnsavedBar from "../../../../../hooks/useUnsavedBar";
import AndOrContainer from "../../AndOrGrouping/AndOrContainer";
import { LAUNCH_DARKLY_AND_OR_CONDITIONS_IN_FLOW_BUILDER } from "../../../../../utils/constant";
import { selectAppState } from "../../../../../app/slice/appSlice";

const EditConditionView = () => {
  const { t } = useTranslation();
  const { error } = useToast();
  const {
    selectedCondition,
    handleUpdateConditionName,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    deletingCondition,
    handleDeleteCondition,
  } = useConditions();
  const { loadingCondition } = useAppSelector(selectConditionsState);
  const { ldFlags } = useAppSelector(selectAppState);
  const { preventAction } = useUnsavedBar();
  const [conditionName, setConditionName] = useState(selectedCondition?.name);
  const [isConditionNameHovered, setIsConditionNameHovered] = useState(false);
  const [, setIsSubConditionHovered] = useState(false);
  const [isEditName, setIsEditName] = useState(true);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const isAndOrConditionsInFlowBuilder = ldFlags?.[LAUNCH_DARKLY_AND_OR_CONDITIONS_IN_FLOW_BUILDER];

  useEffect(() => {
    setConditionName(selectedCondition?.name);
    return () => {
      setIsSubConditionHovered(false);
    };
  }, [selectedCondition?.name]);

  const updateConditionsDetail = useCallback(async () => {
    if (!conditionName) {
      error(t("Please enter a condition name"));
      setConditionName(selectedCondition?.name || "");
      return;
    }

    if (selectedCondition && conditionName !== selectedCondition?.name) {
      handleUpdateConditionName(selectedCondition.id, selectedCondition?.name, conditionName);
    }
  }, [conditionName, selectedCondition, handleUpdateConditionName, error, t]);

  const handleConditionNameChange = useCallback((value: string) => {
    setConditionName(value);
  }, []);

  const handleOnClose = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, [setIsDeleteModalOpen]);

  if (loadingCondition) {
    return (
      <Center>
        <Spinner variant={"ring"} size={"lg"} mt={20} />
      </Center>
    );
  }

  return (
    <>
      <Box p={4} overflow={"auto"} height={"100%"}>
        <ConditionTitle />
        <Divider />
        {/* <Box my="16px">
          {isEditName ? (
            <Box my="16px">
              <Typography color={"#6A7381"} width={"max-content"} bg={"var(--Natural-100, #F9FAFC)"} variant="small">
                {t("Condition name")}
              </Typography>
              <Input
                mt="4px"
                value={conditionName}
                onBlur={() => {
                  setIsConditionNameHovered(false);
                  isEditName && updateConditionsDetail();
                }}
                onChange={e => handleConditionNameChange(e.target.value)}
              />
            </Box>
          ) : (
            <Flex
              my="16px"
              justifyContent={"space-between"}
              onMouseEnter={() => setIsConditionNameHovered(true)}
              onMouseLeave={() => setIsConditionNameHovered(false)}
            >
              <Typography variant="subheading2" fontWeight={500}>
                {conditionName}
              </Typography>
              {isConditionNameHovered && <Icons.PencilSimple onClick={() => setIsEditName(true)} cursor={"pointer"} />}
            </Flex>
          )}
        </Box>
        <Divider /> */}
        {/* {!isConditionEditModeOpened && (
          <Flex my="16px" gap={"8px"} flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              onMouseEnter={() => setIsSubConditionHovered(true)}
              onMouseLeave={() => setIsSubConditionHovered(false)}
            >
              <Typography variant="subheading2" fontWeight={500}>
                {selectedCondition?.rule?.all[0]?.fact === "SPECIFIC_PRODUCT"
                  ? "Specific Product"
                  : selectedCondition?.rule?.all[0]?.fact}
              </Typography>
              {isSubConditionHovered && (
                <Icons.PencilSimple cursor={"pointer"} onClick={() => setIsConditionEditModeOpened(true)} />
              )}
            </Flex>

            <Typography variant="body" color={"#6A7381"}>
              {getConditionVerbose(selectedCondition?.rule?.all[0]?.extraFields?.condition)} in the cart&nbsp;
              {getConditionOperatorVerbose(selectedCondition?.rule?.all[0]?.extraFields?.operatorValue)} of&nbsp;
              {selectedCondition?.rule?.all[0]?.extraFields?.productIds?.length} products
            </Typography>
          </Flex>
        )} */}
        {/* {isConditionEditModeOpened && ( */}

        <AndOrContainer />
        {/* )} */}
      </Box>

      <Box sx={{ position: "fixed", bottom: "0px", width: "100%" }} bg="white" boxShadow="md">
        <Divider />
        <Flex
          pt={4}
          pl={4}
          pr={4}
          pb={"20px"}
          onClick={() => preventAction(() => setIsDeleteModalOpen(true))}
          cursor={"pointer"}
        >
          <IconButton height={"16px"} aria-label={"delete-variant"} variant="unstyled">
            <>
              <Icons.TrashSimple height={"16px"} width={"16px"} color={"#EA3323"} />
              <Typography
                ml={2}
                display={"inline"}
                alignItems={"center"}
                variant="small"
                color={"#EA3323"}
                fontWeight={"medium"}
              >
                {t("Delete condition")}
              </Typography>
            </>
          </IconButton>
        </Flex>
      </Box>
      <AlertDialogModal
        title={`${t("Are you sure you want to delete")} ${selectedCondition?.name}?`}
        okButtonText={t("Delete Condition")}
        size={"2xl"}
        isOpen={isDeleteModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleOnClose}
        okButtonOnClick={handleDeleteCondition}
        cancelButtonOnClick={handleOnClose}
        confirmLoading={deletingCondition}
      >
        <Typography variant="subheading2">
          {t(
            "This action will also delete all widgets you've created inside the condition, and is irreversible. Please make sure you are not deleting anything you still need.",
          )}
        </Typography>
      </AlertDialogModal>
    </>
  );
};

export default React.memo(EditConditionView);
