import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { countAdminBannerClick, countAdminBannerImp, getFlowBuilderBanner } from "../../services/app.service";

export interface BannerItem {
  banner_id: number;
  banner_name: string;
  banner_type: string;
  title: string;
  description: string;
  button_text: string;
  button_link: string;
  is_dismissible: string;
  position: string;
  display_rules: string;
  cnt_impression: number;
  status: string;
  is_deleted: string;
  arrange_banners: number;
  created_on: string;
  updated: string;
}

interface BannerState {
  isBannerLoading: boolean;
  flowBanners: BannerItem[];
  error?: string;
}

interface BannerClickPayload {
  item: any;
  shopName: string;
  isDismiss: boolean;
}

const initialState: BannerState = {
  isBannerLoading: true,
  flowBanners: [],
};

export const initializeBanners = createAsyncThunk("banner/initializeBanners", async (_, { getState }) => {
  const state = getState() as RootState;
  const shopName = state.app.storeInfo?.storeUrl;

  try {
    const flowBuilderBanners = await getFlowBuilderBanner();
   
    const filteredBanner = [
      ...(flowBuilderBanners.bannerData || []),
    ].filter(currentBanner => {
      const getBannersImp = localStorage.getItem(`bannerImp_${currentBanner?.banner_id}_${shopName}`) || "0";
      const getBannersClick = localStorage.getItem(`bannerClick_${currentBanner?.banner_id}_${shopName}`) || "0";
     
      if (currentBanner?.display_rules === "0" && getBannersClick === "0") {
        return true;
      } else if (currentBanner?.display_rules === "1" && Number(getBannersImp) < currentBanner?.cnt_impression) {
        return true;
      } else if (currentBanner?.display_rules === "2") {
        return true;
      } else {
        return false;
      }
    });

    const filterBannersByPosition = (position: string) => {
   
      const uniqueBannerIds = new Set<string>();
      
      return filteredBanner.filter(banner => {
        if (banner.position === position && !uniqueBannerIds.has(banner.banner_id.toString())) {
          uniqueBannerIds.add(banner.banner_id.toString());
     
          return true;
        
        }
       
        return false;
      });
    };


    const flowBanners = filterBannersByPosition("5");

    const bannerIds = [
      ...flowBanners.map(banner => banner.banner_id),
    ];


    bannerIds.forEach(bannerID => {
      const getBannersImp = localStorage.getItem(`bannerImp_${bannerID}_${shopName}`) || "0";
     
      localStorage.setItem(`bannerImp_${bannerID}_${shopName}`, (Number(getBannersImp) + 1).toString());
    });

    countAdminBannerImp({ banner_id: bannerIds });

    return { flowBanners, isBannerLoading: false };
  } catch (error) {
    console.error(error);
    return { isBannerLoading: false, error: "Failed to initialize banners." };
  }
});

const bannerSlice = createSlice({
  name: "banner",
  initialState,
 
  reducers: {
    bannerClick(state, action: PayloadAction<BannerClickPayload>) {
      const { item, shopName, isDismiss } = action.payload;
      const getBannersClick = localStorage.getItem(`bannerClick_${item.banner_id}_${shopName}`) || "0";
     
      localStorage.setItem(`bannerClick_${item.banner_id}_${shopName}`, (Number(getBannersClick) + 1).toString());

      countAdminBannerClick({banner_id:item.banner_id});

      if (isDismiss) {
        if (item.position === "5") {
          state.flowBanners = state.flowBanners.filter(a => a.banner_id !== item.banner_id);
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(initializeBanners.pending, state => {
        state.isBannerLoading = true;
        state.error = undefined;
      })
      .addCase(initializeBanners.fulfilled, (state, action) => {
        state.isBannerLoading = action.payload.isBannerLoading;
        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          state.flowBanners = action.payload.flowBanners || [];
        }
      })
      .addCase(initializeBanners.rejected, (state, action) => {
        state.isBannerLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { bannerClick } = bannerSlice.actions;

export const selectBannerState = (state: RootState) => state.banner;

export default bannerSlice.reducer;
