import { Box, Flex } from "@chakra-ui/react";
import { Icons, ToolTip, Typography, useFlowBuilderSession } from "@reconvert/react-ui-component";
import "./Sidebar.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useUnsavedBar from "../../hooks/useUnsavedBar";
import { selectAppState, setActivePage, setIsSidebarSticked, toggleSideBar } from "../../app/slice/appSlice";
import { LinkItem } from "../../app/slice/appSlice.model";
import { Platform } from "../../models";
import {
  LAUNCH_DARKLY_CHECKOUT_PAGE_KEY,
  LAUNCH_DARKLY_ORDER_STATUS_PAGE_KEY,
  LAUNCH_DARKLY_POST_PURCHASE_PAGE_KEY,
  LAUNCH_DARKLY_THANK_YOU_PAGE_KEY,
} from "../../utils/constant";
import { APP_PREFIX } from "../../config";
import { PageType } from "@reconvert/reconvert-utils";
import DynamicBanner from "../DynamicBanner/DynamicBanner";

export const PageLinkItems: Array<LinkItem> = [
  {
    name: "Checkout",
    icon: <Icons.CreditCard color="#6A7381" />,
    type: PageType.CHECKOUT,
    activeIcon: <Icons.CreditCard height={"20px"} width={"20px"} color="primary.400" />,
    allowedPlatform: [Platform.Shopify, Platform.Wix],
    launchDarklyFlag: LAUNCH_DARKLY_CHECKOUT_PAGE_KEY,
  },
  {
    name: "Post purchase 1st",
    icon: <Icons.ShoppingBag color="#6A7381" />,
    type: PageType.POST_PURCHASE_PAGE1,
    activeIcon: <Icons.ShoppingBag height={"20px"} width={"20px"} color="primary.400" />,
    allowedPlatform: [Platform.Shopify],
    launchDarklyFlag: LAUNCH_DARKLY_POST_PURCHASE_PAGE_KEY,
  },
  {
    name: "Post purchase 2nd",
    icon: <Icons.ShoppingBag color="#6A7381" />,
    type: PageType.POST_PURCHASE_PAGE2,
    activeIcon: <Icons.ShoppingBag height={"20px"} width={"20px"} color="primary.400" />,
    allowedPlatform: [Platform.Shopify],
    launchDarklyFlag: LAUNCH_DARKLY_POST_PURCHASE_PAGE_KEY,
  },
  {
    name: "Thank you page",
    icon: <Icons.CircleWavyCheck color="#6A7381" />,
    type: PageType.THANK_YOU_PAGE,
    activeIcon: <Icons.CircleWavyCheck height={"20px"} width={"20px"} color="primary.400" />,
    allowedPlatform: [Platform.Shopify],
    launchDarklyFlag: LAUNCH_DARKLY_THANK_YOU_PAGE_KEY,
  },
  {
    name: "Order status page",
    icon: <Icons.OrderStatus color="#6A7381" />,
    type: PageType.ORDER_STATUS_PAGE,
    activeIcon: <Icons.OrderStatus height={"20px"} width={"20px"} color="primary.400" />,
    allowedPlatform: [Platform.Shopify],
    launchDarklyFlag: LAUNCH_DARKLY_ORDER_STATUS_PAGE_KEY,
  },
];

const ActionLinkItems: Array<LinkItem> = [
  // {
  //   name: "Add to card",
  //   icon: <Icons.ShoppingCart color="#6A7381" />,
  //   type: PageType.CART_PAGE,
  //   activeIcon: <Icons.ShoppingCart height={"20px"} width={"20px"} color="primary.400" />,
  //   allowedPlatform: [Platform.Shopify],
  // },
];

export const SECTIONS = [
  {
    name: "PAGES",
    links: PageLinkItems,
    enabled: true,
  },
  // {
  //   name: "ACTIONS",
  //   links: ActionLinkItems,
  //   enabled: false,
  // },
];

export const DashboardVersion2Pages = [
  PageType.POST_PURCHASE_PAGE1,
  PageType.POST_PURCHASE_PAGE2,
  PageType.THANK_YOU_PAGE,
  PageType.ORDER_STATUS_PAGE,
];

const Sidebar = () => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const { activePage, isSidebarCollapased, isSidebarSticked, sideBarSections } = useAppSelector(selectAppState);
  const { preventAction } = useUnsavedBar();
  const { setPageInSession } = useFlowBuilderSession();

  const dispatch = useAppDispatch();

  useEffect(() => setPageInSession(activePage), [activePage]);

  useEffect(() => {
    const reconvertImage = new Image();

    reconvertImage.src = APP_PREFIX + "/re-convert.svg";
    reconvertImage.onload = () => console.log("reconvert-Image preloaded");
    reconvertImage.onerror = error => console.error("Error preloading reconvert-Image", error);

    return () => {
      reconvertImage.onload = null;
      reconvertImage.onerror = null;
    };
  }, []);

  return (
    <Box
      sx={{
        minH: "100vh",
        bg: "grays.1",
        boxShadow: "shadow-med",
      }}
      className={`sidebar ${!isSidebarCollapased || hover ? "sidebar--open" : ""}`}
      position="absolute"
      zIndex="2"
      onMouseLeave={() => {
        if (hover) {
          !isSidebarSticked && dispatch(toggleSideBar());
          setHover(false);
        }
      }}
      onMouseEnter={() => {
        if (isSidebarCollapased) {
          setHover(true);
          dispatch(toggleSideBar());
        }
      }}
    >
      <Flex
        sx={{
          padding: "24px",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <>
          <img src={APP_PREFIX + "/reconvert-circle-logo.svg"} width={"32px"} height={"32px"} alt="Logo" />

          {!isSidebarCollapased && (
            <>
              <img
                src={APP_PREFIX + "/re-convert.svg"}
                alt="reconvert_icon"
                style={{ padding: "8px 0 8px 8px" }}
                height={"16px"}
                width={"98px"}
              />

              <Icons.CollapsedMenuLeft
                onClick={() => {
                  hover && setHover(false);
                  isSidebarSticked && !isSidebarCollapased && !hover && setHover(true);
                  dispatch(setIsSidebarSticked(!isSidebarSticked));
                }}
                style={{ transform: !isSidebarSticked ? "scaleX(-1)" : "" }}
                color="neutrals.600"
                cursor="pointer"
                position="absolute"
                right={"28px"}
              />
            </>
          )}
        </>
      </Flex>

      {sideBarSections.map((sec, mainIndex) => {
        return (
          <Box px="20px">
            <Typography
              variant="body"
              color="neutrals.600"
              fontWeight={"medium"}
              mb={2}
              whiteSpace={"nowrap"}
              style={{ fontVariant: "titling-caps", visibility: isSidebarCollapased ? "hidden" : "visible" }}
            >
              {t(sec.name)}
            </Typography>
            {sec.links.map((link, index) => (
              <ToolTip alignItems="center" placement="top" label={link.toolTip} hidden={!link.disabled}>
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: 2,
                    h: 10,
                    alignItems: "center",
                    p: "8px",
                    my: 2,
                    justifyContent: "center",
                    borderRadius: "lg",
                    cursor: "pointer",
                    height: "min-content",
                    minWidth: "36px",
                    width: isSidebarCollapased ? "fit-content" : "auto",
                  }}
                  className="sidebar-position"
                  bg={link.type === activePage ? "neutrals.300" : undefined}
                  _hover={{
                    bg: "neutrals.300",
                    borderRadius: 8,
                  }}
                  onClick={() => preventAction(() => {
                    if (link.disabled) return;

                    dispatch(setActivePage(link.type as PageType));
                  })}
                >
                  <Flex width={"20px"}>{link.type === activePage ? link.activeIcon : link.icon}</Flex>
                  {!isSidebarCollapased && (
                    <>
                      <Typography
                        variant="body"
                        fontWeight={500}
                        flex={1}
                        color={link.disabled ? "disable.color" : link.type === activePage ? "primary.400" : "black"}
                      >
                        {t(link.name)}
                      </Typography>{" "}
                      {/* {mainIndex !== SECTIONS.filter(a=>a.enabled).length - 1 && ( */}
                      <Icons.CaretRight color={link.type === activePage ? "primary.400" : "neutrals.600"} />
                      {/* )} */}
                    </>
                  )}
                </Box>
              </ToolTip>
            ))}
            {/* {mainIndex !== sideBarSections.length - 1 && <Divider orientation="horizontal" my={4} />} */}
          </Box>
        );
      })}
     {!isSidebarCollapased && <DynamicBanner direction={"left"} />}
    </Box>
  );
};

export default Sidebar;
