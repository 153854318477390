import React, { useEffect, useMemo, useRef, useState } from "react";
import useVariantAnalytics from "../../../../../hooks/useVariantAnalytics";
import {
  Button,
  Divider,
  Flex,
  InputGroup,
  InputRightElement,
  NumberInput,
  NumberInputField,
  Spinner,
} from "@chakra-ui/react";
import { AlertDialogModal, Icons, Typography } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
import useVariants from "../../../../../hooks/useVariants";
import useUnsavedBar from "../../../../../hooks/useUnsavedBar";
import VariantAnalytics from "../../VariantAnalytics";
import AnalyticsRow from "../../../../VariantCard/component/AnalyticsRow";
import { useAppSelector } from "../../../../../app/hooks";
import { selectAppState } from "../../../../../app/slice/appSlice";
import { prepareVariantAnalyticsRow } from "../../../../VariantCard/component/AnalyticView";

const VariantABTest = () => {
  const { t } = useTranslation();
  const [selectedVariant, setSelectedVariant] = useState<any>();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const {
    deletingVariant,
    handleDeleteVariantId,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    navigateToEditor,
    handleUpdateVariantImpressionShare,
    variants,
  } = useVariants();

  const handleOnClose = () => {
    setSelectedVariant(undefined);
    setIsDeleteModalOpen(false);
  };

  return (
    <Flex flexDirection="column">
      {/* <Flex>
        <Typography variant="xsmall" color="neutrals.600">
          A/B testing start date:{" "}
        </Typography>
        <Typography variant="xsmall" color="neutrals.600" fontWeight={600}>
          22/09/2022
        </Typography>
      </Flex>
      */}
      <Divider my={4} />
      {variants?.map(variant => (
        <SingleVariantView
          variant={variant}
          key={variant?.id}
          navigateToEditor={navigateToEditor}
          handleUpdateVariantImpressionShare={handleUpdateVariantImpressionShare}
        >
          <WidgetView variant={variant} />
          <Flex justifyContent="flex-end" w={"100%"} mt={2}>
            <Icons.TrashSimple
              cursor="pointer"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setSelectedVariant(variant);
              }}
            />
          </Flex>
        </SingleVariantView>
      ))}
      <AlertDialogModal
        title={`${t("Are you sure you want to delete")} ${selectedVariant?.name}?`}
        okButtonText={t("Delete variant")}
        size={"2xl"}
        isOpen={isDeleteModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleOnClose}
        children={null}
        okButtonOnClick={() => handleDeleteVariantId(selectedVariant?.id)}
        cancelButtonOnClick={handleOnClose}
        confirmLoading={deletingVariant}
      />
    </Flex>
  );
};

interface SingleVariantViewProps {
  children: React.ReactNode;
  variant: any;
  navigateToEditor: (variantId: string) => void;
  handleUpdateVariantImpressionShare: (variantId: string, old: number, newValue: number) => void;
}

const SingleVariantView = ({
  children,
  variant,
  navigateToEditor,
  handleUpdateVariantImpressionShare,
}: SingleVariantViewProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const { preventAction } = useUnsavedBar();
  const [abImpressionShare, setAbImpressionShare] = useState(variant?.abImpressionShare);
  const previousAbImpressionShareRef = useRef(abImpressionShare);

  useEffect(() => {
    setAbImpressionShare(variant?.abImpressionShare);
  }, [variant?.abImpressionShare]);

  return (
    <Flex w="100%" flexDirection="column" mb={4}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py="8px"
        borderBottom="1px solid"
        borderColor="neutrals.200"
      >
        <Flex alignItems="flex-end" gap="4px">
          <Typography variant="subheading1" fontWeight={500} color="neutrals.900" lineHeight={"24px"}>
            {variant?.name}
          </Typography>
        </Flex>
        {isOpen ? (
          <Icons.CaretUp
            cursor="pointer"
            height="16px"
            width="16px"
            color="neutrals.700"
            onClick={() => setIsOpen(!isOpen)}
          />
        ) : (
          <Icons.ChevronDown
            cursor="pointer"
            height="16px"
            width="16px"
            color="neutrals.700"
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
      </Flex>
      <Button
        variant="secondary"
        size={"sm"}
        fontSize={16}
        px={4}
        fontWeight={500}
        flexGrow={1}
        onClick={() => preventAction(() => navigateToEditor(variant?.id))}
      >
        {t("Edit Variant")}
      </Button>
      <Flex alignItems="center" justifyContent="space-between" mt="12px">
        <Typography variant="body" color="neutrals.900" fontWeight={400} flex={1}>
          {t("Variant impression share")}
        </Typography>
        <InputGroup maxW="74px">
          <NumberInput
            value={abImpressionShare}
            max={100}
            min={0}
            onChange={val => {
              const numericVal = parseInt(val, 10);

              if (numericVal > 100 || numericVal < 0) return;

              setAbImpressionShare(isNaN(numericVal) ? 0 : numericVal);
            }}
            onBlur={() => {
              if (previousAbImpressionShareRef.current !== abImpressionShare) {
                handleUpdateVariantImpressionShare(variant?.id, variant?.abImpressionShare, abImpressionShare);
                previousAbImpressionShareRef.current = abImpressionShare;
              }
            }}
          >
            <NumberInputField color={"#1A202C"} />
          </NumberInput>
          <InputRightElement>%</InputRightElement>
        </InputGroup>
      </Flex>
      <Flex flexDirection="column">
        {isOpen && (
          <>
            <VariantAnalyticsSection variantId={variant?.id} status={variant?.status} />
            {children}
          </>
        )}
      </Flex>
      <Divider mt={3} />
    </Flex>
  );
};

const WidgetView = ({ variant }: { variant: any }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { variantAnalyticData, loading, fetchAnalytics } = useVariantAnalytics();
  const {
    dateRange,
    view: { isAnalyticView },
  } = useAppSelector(selectAppState);

  useEffect(() => {
    if (isAnalyticView && variant?.id && isOpen) {
      fetchAnalytics(variant?.id, "WIDGET");
    }
  }, [dateRange, isAnalyticView, isOpen]);

  return (
    <Flex flexDirection="column" w={"100%"} mt="12px">
      <Flex>
        {!isOpen ? (
          <Icons.FilledArrowRight cursor="pointer" onClick={() => setIsOpen(!isOpen)} />
        ) : (
          <Icons.FilledArrowDown cursor="pointer" onClick={() => setIsOpen(!isOpen)} />
        )}
        <Typography variant="body" color="neutrals.700" fontWeight={500}>
          Widgets
        </Typography>
      </Flex>

      {isOpen ? (
        <Flex>
          {loading ? (
            <Flex justifyContent={"center"} mt="3" width="100%">
              <Spinner />
            </Flex>
          ) : (
            <VariantAnalytics variantAnalyticData={variantAnalyticData} isABTestVariant />
          )}
        </Flex>
      ) : (
        <Flex flexDirection="column" mt={2}>
          {variant.widgets
            .filter((a: any) => a.category === "CONTAINER")
            .map((widget: any) => (
              <Typography variant="body" color="neutrals.900" fontWeight={400} key={widget.id}>
                {widget?.name}
              </Typography>
            ))}
        </Flex>
      )}
    </Flex>
  );
};

const VariantAnalyticsSection = ({ variantId, status }: { variantId: string; status: boolean }) => {
  const {
    dateRange,
    view: { isAnalyticView },
    activePage,
  } = useAppSelector(selectAppState);
  const { t } = useTranslation();
  const { variantSummaryAnalyticData, loading, fetchAnalytics } = useVariantAnalytics();

  useEffect(() => {
    if (variantId && isAnalyticView) {
      fetchAnalytics(variantId, "SLOT_VARIANT");
    }
  }, [dateRange, isAnalyticView]);

  const analyticsDetails = useMemo(() => {
    if (variantSummaryAnalyticData[0]) {
      return prepareVariantAnalyticsRow(t, activePage!, variantSummaryAnalyticData[0]);
    } else {
      return null;
    }
  }, [variantSummaryAnalyticData, activePage]);

  return (
    <Flex flexDirection="column" mt={2}>
      {loading ? (
        <Flex justifyContent="center" width="100%">
          <Spinner />
        </Flex>
      ) : (
        <Flex flexDirection="column">
          {analyticsDetails?.map((val: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <AnalyticsRow
                  key={index}
                  name={val?.name}
                  value={val?.currentValue}
                  isForSidebar
                  change={val?.diffInPercentage}
                  variantStatus={status}
                  iconSize={"sm"}
                />
              </React.Fragment>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

export default VariantABTest;
