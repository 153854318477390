import { PageType } from "@reconvert/reconvert-utils";
import { AddCondition, Condition, ConditionInfo, DefaultCondition } from "../models";
import {
  CHECKOUT_PROFILE,
  CREATE_VARIANTS,
  CreateOrReadResponseBody,
  DELETE_CONDITIONS_BY_ID,
  DELETE_SLOT,
  DELETE_VARIANTS_BY_ID,
  GET_ALL_VARIANT_ANALYTICS,
  GET_CONDITION,
  GET_CONDITION_BY_ID,
  GET_PRODUCT,
  GET_TAGS,
  GET_PRODUCT_COLLECTION,
  GET_VARIANT,
  GET_VARIANTS_ANALYTICS,
  SLOT,
  UPDATE_CONDITION,
  UPDATE_CONDITIONS,
  UPDATE_SLOT,
  UPDATE_VARIANT_BY_ID,
  UpdateOrDeleteResponseBody,
  GET_PRODUCT_SALES_CHANNEL,
  GET_LANGUAGE,
  GET_COUNTRIES,
  UPDATE_SLOT_CONFIGURATION,
  GET_VARIANTS_LIST,
  GET_WIDGET_ANALYTICS,
  GET_CUSTOMER_SEGEMENTS,
} from "../utils/constant";
import { deleteJson, getJson, patchJson, postJson, putJson } from "../utils/network";

export async function getAllCondition(activePage: PageType) {
  const { response } = await getJson<CreateOrReadResponseBody<Condition[]>>(`${GET_CONDITION}?location=${activePage}`);

  return response;
}

export async function getAllPageConditions() {
  const { response } = await getJson<CreateOrReadResponseBody<Condition[]>>(`${GET_CONDITION}?location=ALL`);

  return response;
}

export async function getAllSlot(activePage: PageType) {
  const { response } = await getJson<CreateOrReadResponseBody<Condition[]>>(`${SLOT}?location=${activePage}`);

  return response;
}

export async function getConditionById(id: string) {
  const path = GET_CONDITION_BY_ID(id);

  const { response } = await getJson<CreateOrReadResponseBody<ConditionInfo>>(path);

  return response;
}

export async function createCondition(data: DefaultCondition | AddCondition) {
  const path = GET_CONDITION;
  const { response } = await postJson<CreateOrReadResponseBody<Condition>>(path, data);

  return response;
}

export async function updateCondition(id: string, data: any) {
  const path = UPDATE_CONDITION(id);
  const { response } = await putJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function updateConditions(data: any) {
  const path = UPDATE_CONDITIONS;
  const { response } = await putJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function createVariants(data: any) {
  const { response } = await postJson<CreateOrReadResponseBody>(CREATE_VARIANTS, data);

  return response;
}

export async function createSlot(data: any) {
  const { response } = await postJson<UpdateOrDeleteResponseBody>(SLOT, data);

  return response;
}

export async function updateSlot(slotId: string, data: any) {
  const path = UPDATE_SLOT(slotId);
  const { response } = await putJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function updateSlotConfiguration(slotConfigId: string, data: any) {
  const path = UPDATE_SLOT_CONFIGURATION(slotConfigId);
  const { response } = await putJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function updateVariants(id: string, data: any) {
  const path = UPDATE_VARIANT_BY_ID(id);
  const { response } = await patchJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function deleteVariants(id: string) {
  const path = DELETE_VARIANTS_BY_ID(id);

  const { response } = await deleteJson<UpdateOrDeleteResponseBody>(path);

  return response;
}

export async function deleteCondition(id: string) {
  const path = DELETE_CONDITIONS_BY_ID(id);
  const { response } = await deleteJson<UpdateOrDeleteResponseBody>(path);

  return response;
}

export async function deleteSlot(slotNo: number, location: string) {
  const path = DELETE_SLOT(slotNo, location);

  const { response } = await deleteJson<UpdateOrDeleteResponseBody>(path);

  return response;
}

export async function getProduct(data: any) {
  const { response } = await postJson<UpdateOrDeleteResponseBody>(GET_PRODUCT, data);

  return response;
}

export async function getTags() {
  const { response } = await getJson<UpdateOrDeleteResponseBody>(GET_TAGS);

  return response;
}

export async function getProductCollection(data: any) {
  const { response } = await getJson<UpdateOrDeleteResponseBody>(GET_PRODUCT_COLLECTION, data);

  return response;
}

export async function getVariant(productIds: any) {
  const path = GET_VARIANT(encodeURIComponent(productIds));
  const { response } = await getJson<UpdateOrDeleteResponseBody>(path);

  return response;
}

export async function getAllVariantsAnalytics(fromDate: string, toDate: string, conditionId: string, location: string) {
  const path = GET_ALL_VARIANT_ANALYTICS(fromDate, toDate, conditionId, location);
  const { response } = await getJson<UpdateOrDeleteResponseBody>(path, undefined, true);

  return response;
}

export async function getVariantAnalytics(fromDate: string, toDate: string, variantId: string, location: string) {
  const path = GET_VARIANTS_ANALYTICS(fromDate, toDate, variantId, location);
  const { response } = await getJson<UpdateOrDeleteResponseBody>(path, undefined, true);

  return response;
}

export async function getWidgetAnalytics(fromDate: string, toDate: string, variantId: string, location: string) {
  const path = GET_WIDGET_ANALYTICS(fromDate, toDate, variantId, location);
  const { response } = await getJson<UpdateOrDeleteResponseBody>(path, undefined, true);

  return response;
}

export async function getCheckoutProfileId(platformStoreId: string) {
  const path = CHECKOUT_PROFILE;

  const { response } = await getJson<any>(path);

  return response;
}

export async function getProductSalesChannels(data: any) {
  const { response } = await getJson<UpdateOrDeleteResponseBody>(GET_PRODUCT_SALES_CHANNEL, data);

  return response;
}

export async function getLanguage() {
  const { response } = await getJson<UpdateOrDeleteResponseBody>(GET_LANGUAGE);

  return response;
}

export async function getCountries() {
  const { response } = await getJson<UpdateOrDeleteResponseBody>(GET_COUNTRIES);

  return response;
}

export async function getCustomerSegment() {
  const { response } = await getJson<UpdateOrDeleteResponseBody>(GET_CUSTOMER_SEGEMENTS);

  return response;
}

export async function getVariantsList(location: string) {
  const { response } = await getJson<UpdateOrDeleteResponseBody>(GET_VARIANTS_LIST, { location, excludeEmptyWidgetVariant: true });

  return response;
}