import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllVariantsAnalytics } from "../../services/conditions.service";
import { RootState } from "../store";

import { dateDifferenceInDays, getCalculatedData, subtractDaysAndFormat } from "../../utils/helpers";

export interface AnalyticsDiff {
  impressionsDiffPercentage?: string;
  clicksDiffPercentage?: string;
  conversionsDiffPercentage?: string;
  revenueDiffPercentage?: string;
  cvrDiffPercentage?: string;
  ctrDiffPercentage?: string;
  declinedDiffPercentage?: string;
}

export type AnalyticsRecord = {
  [key: string]: number | string | AnalyticsDiff;
  baseName: string;
  baseId: string;
  location: string;
  category: string;
  conditionId: string;
  conditionName: string;
  slotId: string;
  slotName: string;
  variantId: string;
  variantName: string;
  widgetId: string;
  widgetName: string;
  parentWidgetId: string;
  parentWidgetName: string;
  impressions: number;
  clicks: number;
  conversions: number;
  revenue: number;
  cvr: number;
  ctr: number;
  declined: number;
};

interface AnalyticsState {
  loading: boolean;
  error?: boolean;
  variantsAnalytics: AnalyticsRecord[];
}

const initialState: AnalyticsState = {
  loading: true,
  variantsAnalytics: [],
};

export const getAllVariantAnalytics = createAsyncThunk(
  "analytics/getAllVariantAnalytics",
  async (selectedConditionId: string, { getState }) => {
    const state = getState() as RootState;

    const dateDifference = dateDifferenceInDays(
      new Date(state.app.dateRange?.startDate),
      new Date(state.app.dateRange?.endDate),
    );

    const [currentAnalytics, previousAnalytics] = await Promise.all([
      getAllVariantsAnalytics(
        state.app.dateRange?.startDate,
        state.app.dateRange?.endDate,
        selectedConditionId!,
        state.app.activePage!,
      ),
      getAllVariantsAnalytics(
        subtractDaysAndFormat(dateDifference, "YYYY-MM-DD", state.app.dateRange?.startDate),
        state.app.dateRange?.startDate,
        selectedConditionId!,
        state.app.activePage!,
      ),
    ]);

    const calculatedData = getCalculatedData(currentAnalytics?.groupByData, previousAnalytics?.groupByData);

    return calculatedData || [];
  },
);

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllVariantAnalytics.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllVariantAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.variantsAnalytics = action.payload;
        state.error = false;
      })
      .addCase(getAllVariantAnalytics.rejected, (state, action) => {
        console.error("while fetching analytics", action.error.message);
        state.loading = false;
        state.error = true;
      });
  },
});

export const selectVariantAnalysis = (state: RootState) => state.analytics;

export default analyticsSlice.reducer;
