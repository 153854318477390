import { PageType } from "@reconvert/reconvert-utils"

const getkeysArray = (operator: string) => {
    switch (operator) {
      case PageType.CHECKOUT:
        return [PageType.CHECKOUT];
      case PageType.POST_PURCHASE_PAGE1:
        return [PageType.POST_PURCHASE_PAGE1];
      case PageType.POST_PURCHASE_PAGE2:
        return [PageType.POST_PURCHASE_PAGE2];
      case PageType.THANK_YOU_PAGE:
        return [PageType.THANK_YOU_PAGE];
      case PageType.ORDER_STATUS_PAGE:
        return [PageType.ORDER_STATUS_PAGE];
      case `${PageType.POST_PURCHASE_PAGE1}-${PageType.POST_PURCHASE_PAGE2}`:
      case `${PageType.POST_PURCHASE_PAGE1}&${PageType.POST_PURCHASE_PAGE2}`:
        return [PageType.POST_PURCHASE_PAGE1, PageType.POST_PURCHASE_PAGE2];
      default:
        return [];
    }
  }
  
export const handleFilterCondition = (data: any, operator: string) => {
    return getkeysArray(operator).includes(data.location as PageType);
}