import { Box, Divider, Flex } from "@chakra-ui/react";
import { Typography } from "@reconvert/react-ui-component";

const AndSeprator = () => {
  return (
    <Flex>
      <Divider orientation="vertical" height={"auto"} paddingLeft={3} borderLeftWidth={"2px"} />
      <Flex alignItems={"center"} justifyContent={"center"} gap={2} my={4} flexDirection="row" width="100%">
        <Box padding={"2px 8px"} borderRadius={"16px"} background={"#D8F2F8"}>
          <Typography variant="tiny">AND</Typography>
        </Box>
        <Divider borderBottomWidth={"2px"} width="100%" />
      </Flex>
    </Flex>
  );
};

export default AndSeprator;
