import { ADMIN_BANNER_CLICK, ADMIN_BANNER_IMP, GET_FLOW_BUILDER_BANNERS, UpdateOrDeleteResponseBody } from "../utils/constant";
import { getJson, postJson } from "../utils/network";

export async function getFlowBuilderBanner() {
  const { response } = await getJson<UpdateOrDeleteResponseBody>(GET_FLOW_BUILDER_BANNERS);

  return response;
}
export async function countAdminBannerClick(data: any) {
  const { response } = await postJson<UpdateOrDeleteResponseBody>(ADMIN_BANNER_CLICK,data);

  return response;
}
export async function countAdminBannerImp(data: any) {
  const { response } = await postJson<UpdateOrDeleteResponseBody>(ADMIN_BANNER_IMP,data);

  return response;
}
