import { Box, Button, Divider, Flex, Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Icons, Typography, useToast } from "@reconvert/react-ui-component";
import React, { useEffect, useMemo, useState } from "react";
import ConditionSection from "./ConditionSection";
import OrSeprator from "./OrSeprator";
import AndSeprator from "./AndSeprator";

import useAndOrGrouping, { ConditionType } from "../../../../hooks/useAndOrGrouping";
import { useLocation } from "react-router-dom";
import { Platform } from "@reconvert/reconvert-utils";
import { APP_PLATFORM } from "../../../../config";
import { selectAppState } from "../../../../app/slice/appSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Overview, selectConditionsState } from "../../../../app/slice/conditionSlice";
import { startConditionCreateOrUpdate, stopConditionCreateOrUpdate } from "../../../../app/slice/unsavedSlice";
import useConditions from "../../../../hooks/useConditions";
import { LAUNCH_DARKLY_AND_OR_CONDITIONS_IN_FLOW_BUILDER } from "../../../../utils/constant";

const AndOrContainer = () => {
  const {
    conditionData,
    handleOrCondition,
    handleAndCondition,
    handleDeleteCondition,
    updateConditionData,
    handleOnSubmit,
    updateConditionName,
    canSave,
    newlyCreatedSubConditionId,
  } = useAndOrGrouping();

  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const isAdmin = queryParams.get("isAdmin");
  const { error } = useToast();
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const { isUnsavedBarEnabled, ldFlags } = useAppSelector(selectAppState);
  const { selectedCondition, setSelectedConditionId, selectFirstCondition } = useConditions();

  const { overview, updatingConditions } = useAppSelector(selectConditionsState);

  const [isHover, setIsHover] = useState(false);
  const [conditionName, setConditionName] = useState(conditionData?.name || "");
  const [preserveName, setPreserveName] = useState(conditionData?.name || "");

  const dispatch = useAppDispatch();
  const isAndOrConditionsInFlowBuilder = ldFlags?.[LAUNCH_DARKLY_AND_OR_CONDITIONS_IN_FLOW_BUILDER];

  useEffect(() => {
    setConditionName(conditionData?.name || "");
    setPreserveName(conditionData?.name || "");
  }, [conditionData?.name]);

  useEffect(() => {
    if (!isUnsavedBarEnabled) return;

    if (
      overview === Overview.ADD_CONDITION_VIEW ||
      (canSave && overview === Overview.EDIT_CONDITION_VIEW && selectedCondition?.id)
    ) {
      dispatch(startConditionCreateOrUpdate());
    } else {
      dispatch(stopConditionCreateOrUpdate());
    }
  }, [canSave, dispatch, isUnsavedBarEnabled, overview, selectedCondition?.id]);

  useEffect(() => {
    const handleMessage = async (event: any) => {
      if (event?.data?.page !== "flow-builder") return false;

      if (event.data?.isConditionCreateOrUpdate) {
        switch (event.data.event) {
          case "save-form":
            handleOnSubmit();
            break;
          case "discard-form":
            dispatch(stopConditionCreateOrUpdate());
            if (selectedCondition?.id) {
              setSelectedConditionId(selectedCondition?.id);
            } else {
              selectFirstCondition();
            }

            break;
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [conditionData, dispatch, handleOnSubmit, selectFirstCondition, selectedCondition?.id, setSelectedConditionId]);

  const handleConditionNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setPreserveName(e.target.value);
    }

    setConditionName(e.target.value);
  };

  const handleConditionNameBlur = () => {
    setIsEdit(false);
    setIsHover(false);

    if (!conditionName) {
      setConditionName(preserveName);
      return error(t("Condition name is required"));
    } else {
      updateConditionName(conditionName);
    }
  };

  return (
    <>
      {isAndOrConditionsInFlowBuilder ? (
        <Box>
          <Box my={4}>
            <Box>
              {isEdit ? (
                <Flex gap={1} flexDirection={"column"}>
                  <Typography
                    color={"#6A7381"}
                    width={"max-content"}
                    bg={"var(--Natural-100, #F9FAFC)"}
                    variant="small"
                  >
                    {t("Condition name")}
                  </Typography>
                  <Input
                    value={conditionName}
                    onChange={handleConditionNameChange}
                    _hover={{
                      paddingLeft: "12px",
                    }}
                    onBlur={handleConditionNameBlur}
                  />
                </Flex>
              ) : (
                <Flex
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  justifyContent={"space-between"}
                >
                  <Typography
                    textAlign={"left"}
                    variant="subheading1"
                    fontSize={"16px"}
                    color={"neutrals.800"}
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {conditionName}
                  </Typography>

                  {isHover && (
                    <Icons.PencilSimple
                      onClick={() => setIsEdit(true)}
                      cursor={"pointer"}
                      color={"neutrals.800"}
                      width={"16px"}
                      height={"16px"}
                    />
                  )}
                </Flex>
              )}
            </Box>
          </Box>

          {Array.isArray(conditionData?.any) &&
            conditionData?.any.map((condition, index) => (
              <React.Fragment key={condition.id}>
                {index > 0 && condition?.conditionType === ConditionType.OR && <OrSeprator />}
                {index > 0 && condition?.conditionType === ConditionType.AND && <AndSeprator />}
                <ConditionSection
                  condition={condition}
                  handleAndCondition={handleAndCondition}
                  handleDeleteCondition={handleDeleteCondition}
                  updateConditionData={updateConditionData}
                  allCondition={conditionData?.any}
                  newlyCreatedSubConditionId={newlyCreatedSubConditionId}
                  conditionName={conditionData?.name}
                  updateConditionName={updateConditionName}
                />
              </React.Fragment>
            ))}
          <Divider my={4} />
          <Flex gap={2} mt={4} mb={"50px"} cursor={"pointer"} width={"fit-content"} onClick={handleOrCondition}>
            <Icons.PlusCircle />
            <Typography variant="body">OR</Typography>
          </Flex>
        </Box>
      ) : (
        <ConditionSection
          condition={conditionData?.any[0]}
          handleAndCondition={handleAndCondition}
          handleDeleteCondition={handleDeleteCondition}
          updateConditionData={updateConditionData}
          allCondition={conditionData?.any}
          newlyCreatedSubConditionId={newlyCreatedSubConditionId}
          conditionName={conditionData?.name}
          updateConditionName={updateConditionName}
        />
      )}

      {(isAdmin || APP_PLATFORM === Platform.WIX || !isAndOrConditionsInFlowBuilder) && (
        <>
          <Button
            mt={4}
            mb={8}
            onClick={handleOnSubmit}
            width={"100%"}
            isLoading={updatingConditions}
            isDisabled={!canSave}
          >
            Save
          </Button>
          <Divider />
        </>
      )}
    </>
  );
};

export default AndOrContainer;
