import React, { useCallback, useEffect, useMemo } from "react";
import AndOrConditionBox from "./AndOrConditionBox";
import { Box, Divider, Flex } from "@chakra-ui/react";
import { Icons, Typography, useCurrencyFormatter } from "@reconvert/react-ui-component";
import { getConditionText } from "@reconvert/reconvert-utils";
import { useAppSelector } from "../../../../app/hooks";
import { selectAppState } from "../../../../app/slice/appSlice";
import { APP_PLATFORM } from "../../../../config";
import { getConditionsMetaData } from "../../../../utils/conditionsMetaData";
import { ConditionType, ConditonData } from "../../../../hooks/useAndOrGrouping";
import {
  getConditionsParamsForAndOrGrouping,
  validateRequiredValueForAndOrGrouping,
} from "../../../../utils/validateConditionData";
import { Overview, selectConditionsState } from "../../../../app/slice/conditionSlice";
import { LAUNCH_DARKLY_AND_OR_CONDITIONS_IN_FLOW_BUILDER } from "../../../../utils/constant";

const ConditionSection = ({
  condition,
  handleAndCondition,
  handleDeleteCondition,
  updateConditionData,
  allCondition,
  newlyCreatedSubConditionId,
  conditionName,
  updateConditionName,
}: {
  condition: ConditonData;
  handleAndCondition: (parentId: string, conditionId: string) => void;
  handleDeleteCondition: (conditionId: string) => void;
  updateConditionData: (condition: ConditonData) => void;
  allCondition: ConditonData[];
  newlyCreatedSubConditionId: string | null;
  conditionName: string;
  updateConditionName: (name: string) => void;
}) => {
  const appState = useAppSelector(selectAppState);

  const { storeInfo, activePage, ldFlags } = appState as { storeInfo: any; activePage: any; ldFlags: any };
  const { currencyFormatter } = useCurrencyFormatter();
  const { overview } = useAppSelector(selectConditionsState);
  const [isOpen, setIsOpen] = React.useState(false);
  const { isInValid } = validateRequiredValueForAndOrGrouping(condition);
  const [conditionParams, setConditionParams] = React.useState<any>(null);
  const isAndOrConditionsInFlowBuilder = ldFlags?.[LAUNCH_DARKLY_AND_OR_CONDITIONS_IN_FLOW_BUILDER];

  const formatCurrency = useCallback(
    (value: number) => {
      return currencyFormatter(value, storeInfo?.currency!);
    },
    [currencyFormatter, storeInfo?.currency],
  );
  const conditionsMetaData = useMemo(
    () => getConditionsMetaData(activePage!, storeInfo?.isShopifyPlusMerchant!, condition.condition),
    [activePage, storeInfo, condition.condition],
  );

  const getConditionParams = useCallback(async () => {
    const response = await getConditionsParamsForAndOrGrouping(storeInfo?.platformStoreId, activePage!, condition);

    setConditionParams(response);
  }, [storeInfo?.platformStoreId, activePage, condition]);

  useEffect(() => {
    getConditionParams();
  }, [getConditionParams]);

  useEffect(() => {
    if (
      (overview === Overview.ADD_CONDITION_VIEW && allCondition.length === 1) ||
      newlyCreatedSubConditionId === condition?.id
    ) {
      setIsOpen(true);
    }
  }, [overview, newlyCreatedSubConditionId, condition?.id, allCondition.length]);

  const getParentId = (conditionId: string) => {
    const parentCondition = allCondition.find((c: ConditonData) => c.id === conditionId);

    if (parentCondition?.conditionType === ConditionType.OR) {
      return parentCondition?.id;
    }

    return parentCondition?.parentId || conditionId;
  };

  useEffect(() => {
    console.log("@@@ allCondition", allCondition);
  }, [allCondition]);

  return (
    <Box>
      {isAndOrConditionsInFlowBuilder && (
        <Flex>
          <Divider orientation="vertical" height={"auto"} paddingLeft={3} borderLeftWidth={"2px"} />
          <Box w={"100%"}>
            <Flex justifyContent={"space-between"}>
              <Typography variant="body">
                {" "}
                {conditionParams ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        getConditionText(conditionParams, formatCurrency, false, APP_PLATFORM as any, true, true) || "",
                    }}
                  ></span>
                ) : (
                  <span>Loading...</span>
                )}
              </Typography>
              <Flex>
                {isOpen ? (
                  <Icons.CaretUp
                    cursor="pointer"
                    height="16px"
                    width="16px"
                    color="neutrals.700"
                    onClick={() => {
                      !isInValid && setIsOpen(!isOpen);
                    }}
                  />
                ) : (
                  <Icons.ChevronDown
                    cursor="pointer"
                    height="16px"
                    width="16px"
                    color="neutrals.700"
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  />
                )}
              </Flex>
            </Flex>
            <Flex
              gap={2}
              mt={4}
              cursor={"pointer"}
              width={"fit-content"}
              onClick={() => {
                handleAndCondition(getParentId(condition?.id), condition?.id);
              }}
            >
              <Icons.PlusCircle />
              <Typography variant="body">AND</Typography>
            </Flex>
          </Box>
        </Flex>
      )}

      {(isOpen || !isAndOrConditionsInFlowBuilder) && (
        <AndOrConditionBox
          conditionData={condition}
          conditionsMetaData={conditionsMetaData}
          handleDeleteCondition={() => handleDeleteCondition(condition?.id)}
          updateConditionData={updateConditionData}
          allCondition={allCondition}
          conditionName={conditionName}
          updateConditionName={updateConditionName}
        />
      )}
    </Box>
  );
};

export default ConditionSection;
