import React, {  useCallback } from "react";
import Styles from "./DynamicBanner.module.css";
import { Banner } from "@reconvert/react-ui-component";
import { Button, Skeleton } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { selectAppState } from "../../app/slice/appSlice";
import { useAppSelector } from "../../app/hooks";
import { bannerClick, BannerItem } from "../../app/slice/bannerSlice";


interface BannerState {
  isBannerLoading: boolean;
  flowBanners: BannerItem[];
}


interface DynamicBannerProps {
  direction: "top" | "left" | "right";
}


const bannerTypes: Record<string, string> = {
  "0": "default",
  "1": "success",
  "2": "info",
  "3": "warning",
  "4": "error",
};


const selectBanner = (state: { banner: BannerState }) => state.banner;
const RenderSkeleton = () => <Skeleton height={'113px'} mb="10px" />;
const RenderEmptyContent = () => <></>;

export const bannerDetails = createSelector([selectBanner], banner => {
  return {
    flowBannerData: banner.flowBanners || [],
    loading : banner.isBannerLoading,
  };
});


const DynamicBanner: React.FC<DynamicBannerProps> = ({ direction }) => {
  const {flowBannerData,loading } = useSelector(bannerDetails);
  const bannerData = flowBannerData;
  
  if (!flowBannerData || loading) {
    
    return (
      <>
        {direction === 'top' && <RenderSkeleton />}
        {(direction === 'left' || direction === 'right') && (
          <RenderEmptyContent />
        )}
      </>
    );
  }

  return <div>{bannerData?.length > 0 && <BannerLayout item={bannerData[0]} direction={direction} />}</div>;
};

interface BannerLayoutProps {
  item: BannerItem;
  direction: "top" | "left" | "right";
}

const BannerLayout: React.FC<BannerLayoutProps> = React.memo(({ item, direction }) => {
  const dispatch = useDispatch();
  const { storeInfo } = useAppSelector(selectAppState);

  const decodeHTMLEntities = (text: string) => {
    const parser = new DOMParser();

    return parser.parseFromString(`<!doctype html><body>${text}`, "text/html").body.textContent ?? "";
  };

    const handleOnClick = useCallback(
      (isDismiss = false) => {
        dispatch(
          bannerClick({
            item,
            shopName:storeInfo?.storeUrl || '',
            isDismiss
          }),
        );
      },
      [dispatch, storeInfo?.storeUrl,  item],
    );

  const bannerType = bannerTypes[item.banner_type];
  const className: any =
    direction === "top" ? null : direction === "left" ? Styles.Left_dynamic_banner : Styles.Right_dynamic_banner;

  return (
    <div key={item.banner_id} className={className} style={{ marginBottom: "10px" }}>
      <Banner
        title={decodeHTMLEntities(item.title)}
        status={bannerType as any}
        hasBackground
        description=""
        onDismiss={
          item.is_dismissible === '1' ? () => handleOnClick(true) : () => {}
        }
        body={<p dangerouslySetInnerHTML={{ __html: item.description }}></p>}
        footer={
          <Button
            color="link.color"
            variant={"unstyled"}
            onClick={() => {
                handleOnClick();
                if (item.button_link) window.open(item.button_link, "_blank");
            }}
            size="sm"
          >
            {decodeHTMLEntities(item.button_text)}
          </Button>
        }
      />
    </div>
  );
});

export default React.memo(DynamicBanner);
