import { useToast } from "@reconvert/react-ui-component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../app/slice/appSlice";
import { selectConditionsState } from "../app/slice/conditionSlice";
import useConditions from "./useConditions";
import {
  canSaveCondition,
  compareWithOldCondition,
  getConditionObjectForAndOrGrouping,
  getConditionsParamsForAndOrGrouping,
  validateConditionDataForAndOrGrouping,
  validateRequiredValue,
} from "../utils/validateConditionData";
import { getConditionsMetaData } from "../utils/conditionsMetaData";
import { nanoid } from "@reduxjs/toolkit";
import { ConditionFact } from "@reconvert/reconvert-utils";

interface Option {
  value: string;
  label: string;
}

interface OperatorOption {
  value: string;
  label: string;
}

interface ConditionInfo {
  value: string;
  options: Option[];
  operatorOptions: OperatorOption[];
  thirdOperatorOptions?: OperatorOption[];
}

interface ConditionsMetaDataType {
  [key: string]: ConditionInfo;
}

export interface ConditonData {
  name?: string;
  fact: string;
  condition: string;
  operator: string;
  productIds: string[];
  variantIds: string[];
  value?: string;
  fromValue?: string;
  toValue?: string;
  items?: string | string[];
  itemsInfo?: string | string[];
  productCollectionIds?: { id: string; name: string }[];
  thirdOperatorOption?: string;
  id: string;
  conditionType: ConditionType;
  parentId?: string;
}

export interface ConditonDataArray {
  name: string;
  any: ConditonData[];
}

interface EditConditionReturnType {
  conditionData: ConditonDataArray;
  updateConditionData: ({
    id,
    name,
    fact,
    condition,
    operator,
    productIds,
    variantIds,
    value,
    fromValue,
    toValue,
    items,
    itemsInfo,
    productCollectionIds,
    thirdOperatorOption,
  }: {
    id: string;
    name?: string;
    fact?: string;
    condition?: string;
    operator?: string;
    productIds?: string[];
    variantIds?: string[];
    value?: string;
    fromValue?: string;
    toValue?: string;
    items?: string | string[];
    itemsInfo?: string | string[];
    productCollectionIds?: { id: string; name: string }[];
    thirdOperatorOption?: string;
  }) => void;
  handleOnSubmit: () => void;
  canSave: boolean;
  conditionsMetaData: ConditionsMetaDataType;
  handleOrCondition: () => void;
  handleAndCondition: (parentId: string, conditionId: string) => void;
  handleDeleteCondition: (conditionId: string) => void;
  updateConditionName: (name: string) => void;
  newlyCreatedSubConditionId: string | null;
}

export enum ConditionType {
  DEFAULT = "DEFAULT",
  AND = "AND",
  OR = "OR",
}

const initialConditonData: ConditonDataArray = {
  name: "My new Condition",
  any: [
    {
      id: nanoid(),
      conditionType: ConditionType.OR,
      fact: ConditionFact.SPECIFIC_PRODUCT,
      condition: "atLeastOneProduct",
      operator: "is",
      productIds: [],
      variantIds: [],
    },
  ],
};

const useAndOrGrouping = (): EditConditionReturnType => {
  const { platformStoreId, activePage, storeInfo } = useAppSelector(selectAppState);
  const { conditions } = useAppSelector(selectConditionsState);
  const { t } = useTranslation();
  const { error } = useToast();
  const { selectedCondition, handleUpdateCondition, handleCreateCondition } = useConditions();
  const [newlyCreatedSubConditionId, setNewlyCreatedSubConditionId] = useState<string | null>(null);

  const getPlainConditionObject = useCallback((condition: any) => {
    const conditionRule = condition?.rule?.any;
    const flattenedCondition = conditionRule?.flatMap((item: any) => item.all);
    const newConditionObjects = flattenedCondition?.map((item: any) => getConditionObjectForAndOrGrouping(item));

    return {
      name: condition.name,
      any: newConditionObjects,
    };
  }, []);

  const [conditionData, setConditionData] = useState<ConditonDataArray>(
    selectedCondition ? getPlainConditionObject(selectedCondition) : initialConditonData,
  );

  const handleOrCondition = useCallback(() => {
    const uniqueConditionId = nanoid();

    setNewlyCreatedSubConditionId(uniqueConditionId);
    setConditionData(prev => ({
      name: prev.name,
      any: [
        ...prev.any,
        {
          id: uniqueConditionId,
          conditionType: ConditionType.OR,
          fact: ConditionFact.SPECIFIC_PRODUCT,
          condition: "atLeastOneProduct",
          operator: "is",
          productIds: [],
          variantIds: [],
        },
      ],
    }));
  }, []);

  const handleAndCondition = useCallback((parentId: string, conditionId: string) => {
    const uniqueConditionId = nanoid();

    setNewlyCreatedSubConditionId(uniqueConditionId);
    setConditionData(prev => {
      const newConditions = [...prev.any];
      const index = newConditions?.findIndex(condition => condition?.id === conditionId);

      newConditions?.splice(index + 1, 0, {
        id: uniqueConditionId,
        conditionType: ConditionType.AND,
        fact: ConditionFact.SPECIFIC_PRODUCT,
        condition: "atLeastOneProduct",
        operator: "is",
        productIds: [],
        variantIds: [],
        parentId: parentId,
      });

      return {
        name: prev.name,
        any: newConditions,
      };
    });
  }, []);

  const handleDeleteCondition = useCallback((conditionId: string) => {
    setConditionData(prev => {
      try {
        const { any: conditions } = prev;

        const targetCondition = conditions.find(condition => condition.id === conditionId);

        if (!targetCondition) return prev;

        let updatedConditions = conditions.filter(condition => condition.id !== conditionId);

        if (targetCondition.conditionType === ConditionType.OR) {
          const childConditions = conditions.filter(condition => condition.parentId === conditionId);

          if (childConditions.length > 0) {
            const [firstChild, ...remainingChildren] = childConditions;

            updatedConditions = updatedConditions.map(condition =>
              condition.id === firstChild.id
                ? { ...condition, parentId: undefined, conditionType: ConditionType.OR }
                : remainingChildren.some(child => child.id === condition.id)
                  ? { ...condition, parentId: firstChild.id }
                  : condition,
            );
          }
        }

        console.log("@@@ updatedConditions", validateConditionDataForAndOrGrouping(updatedConditions));

        const validatedConditions = validateConditionDataForAndOrGrouping(updatedConditions);

        return { ...prev, any: validatedConditions };
      } catch (error) {
        console.error("Error while deleting condition:", error);
        return prev;
      }
    });
  }, []);

  const conditionsMetaData = useMemo(
    () => getConditionsMetaData(activePage!, storeInfo?.isShopifyPlusMerchant!, conditionData?.any[0]?.condition),
    [activePage, storeInfo, conditionData?.any[0]?.condition],
  );

  function getNextSequenceNumber(items: string[]) {
    const conditionItems = items.filter(item => /My new Condition \(\d+\)/.test(item) || /My new Condition/.test(item));

    const sequenceNumbers = conditionItems.map(item => {
      if (item === "My new Condition") {
        return 1;
      }

      const match = item.match(/My new Condition \((\d+)\)/);

      return match ? parseInt(match[1]) + 1 : 0;
    });

    const nextSequenceNumber = sequenceNumbers.length > 0 ? Math.max(...sequenceNumbers) + 1 : 0;

    return nextSequenceNumber;
  }

  useEffect(() => {
    if (selectedCondition) {
      setConditionData(getPlainConditionObject(selectedCondition));
    } else {
      const number = getNextSequenceNumber(conditions.map(a => a.name));

      if (number > 1) {
        const newItem = `My new Condition (${number - 1})`;

        setConditionData(prev => ({
          name: newItem,
          any: prev.any.map(item => ({
            ...item,
            name: newItem,
          })),
        }));
      }
    }
  }, [selectedCondition, getPlainConditionObject, conditions]);

  const updateConditionName = useCallback((name: string) => {
    setConditionData(prev => ({
      ...prev,
      name: name,
    }));
  }, []);

  const updateConditionData = useCallback(
    ({
      id,
      name,
      fact,
      condition,
      operator,
      productIds,
      variantIds,
      value,
      fromValue,
      toValue,
      items,
      itemsInfo,
      productCollectionIds,
      thirdOperatorOption,
    }: {
      id: string;
      name?: string;
      fact?: string;
      condition?: string;
      operator?: string;
      productIds?: string[];
      variantIds?: string[];
      value?: string;
      fromValue?: string;
      toValue?: string;
      items?: string | string[];
      itemsInfo?: string | string[];
      productCollectionIds?: { id: string; name: string }[];
      thirdOperatorOption?: string;
    }) => {
      const conditionDataArray = conditionData?.any?.map((conditionInfo: any) => {
        if (conditionInfo.id === id) {
          return {
            ...conditionInfo,
            name: name !== undefined ? name : conditionInfo.name,
            fact: fact || conditionInfo.fact,
            condition: condition || conditionInfo.condition,
            operator: operator || conditionInfo.operator,
            productIds: productIds || conditionInfo.productIds,
            variantIds: variantIds || conditionInfo.variantIds,
            items: items || conditionInfo.items,
            itemsInfo: itemsInfo || conditionInfo.itemsInfo,
            value: value !== undefined ? value : conditionInfo?.value,
            fromValue: fromValue !== undefined ? fromValue : conditionInfo?.fromValue,
            toValue: toValue !== undefined ? toValue : conditionInfo?.toValue,
            productCollectionIds: productCollectionIds || conditionInfo.productCollectionIds,
            thirdOperatorOption: thirdOperatorOption || conditionInfo.thirdOperatorOption,
          };
        }

        return conditionInfo;
      });

      setConditionData(prev => ({
        ...prev,
        any: conditionDataArray,
      }));
    },
    [conditionData],
  );

  const highestPriority = useMemo(() => {
    return conditions?.reduce((maxPriority, condition) => {
      const priority = condition.priority || 0;

      return Math.max(maxPriority, priority);
    }, Number.NEGATIVE_INFINITY);
  }, [conditions]);

  const handleOnSubmit = useCallback(async () => {
    const payload: any = {
      platformStoreId,
      location: activePage!,
      name: conditionData.name,
      rule: {
        any: [],
      },
    };

    if (!conditionData?.name) {
      return error(t("Condition name is required"));
    }

    const isAllConditionsValid = conditionData?.any?.every(condition => {
      const { isInValid, isInputvalid } = validateRequiredValue({ ...condition, name: conditionData.name });

      if (!isInputvalid) {
        return error(t("Please enter valid value"));
      }

      if (isInValid) {
        return error(t("Please fill all required field"));
      }

      return isInputvalid && !isInValid;
    });

    if (isAllConditionsValid) {
      const conditionParams = await Promise.all(
        conditionData.any.map(condition =>
          getConditionsParamsForAndOrGrouping(platformStoreId, activePage!, condition),
        ),
      );

      if (
        conditionParams[0] &&
        typeof conditionParams[0] === "object" &&
        "conditionType" in conditionParams[0] &&
        conditionParams[0].conditionType === ConditionType.AND
      ) {
        conditionParams[0] = {
          ...conditionParams[0],
          conditionType: ConditionType.OR,
          parentId: undefined,
        };
      }

      const filteredConditionParams = validateConditionDataForAndOrGrouping(conditionParams);

      const customerSegments = filteredConditionParams.filter((a: any) => a.fact === ConditionFact.CUSTOMER_SEGMENTS);

      if (customerSegments.length > 0) {
        const allItems = customerSegments?.map((item: any) => [...item.value])?.flat();

        payload.metafields = {
          segments: [...new Set(allItems)],
        };
      }

      const facts = [...new Set(filteredConditionParams.map((a: any) => a.fact))];

      filteredConditionParams.forEach((params: any) => {
        if (!params) return;

        if (params?.conditionType === ConditionType.OR) {
          payload.rule.any.push({ all: [params] });
        } else if (params?.conditionType === ConditionType.AND) {
          const parentId = params?.parentId;
          const index = payload.rule.any.findIndex(
            (item: any) => Array.isArray(item.all) && item.all.some((condition: any) => condition.id === parentId),
          );

          if (index !== -1) {
            payload.rule.any[index]?.all?.push(params);
          }
        }
      });

      if (selectedCondition) {
        handleUpdateCondition(selectedCondition.id, { ...payload, facts });
      } else {
        handleCreateCondition({ ...payload, priority: highestPriority + 1, name: conditionData.name, facts });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionData, platformStoreId, activePage, selectedCondition, highestPriority, conditionData.name]);

  const canSave = useMemo(() => {
    if (!selectedCondition) {
      // Creating condition
      const canConditionSave = conditionData?.any?.every(value => {
        return canSaveCondition({ ...value, name: conditionData.name }, activePage);
      });

      return canConditionSave;
    }

    // Editing condition
    const oldCondition = getPlainConditionObject(selectedCondition);

    const removeNameAndIds = (conditions: any[]) => conditions.map(({ id, parentId, name, ...rest }) => rest);

    if (JSON.stringify(removeNameAndIds(conditionData.any)) !== JSON.stringify(removeNameAndIds(oldCondition.any))) {
      return true;
    }

    const value = conditionData?.any?.some((value, index) => {
      return compareWithOldCondition(
        { ...oldCondition.any[index], name: oldCondition.name },
        { ...value, name: conditionData.name },
        activePage,
      );
    });

    return value;
  }, [selectedCondition, conditionData.any, conditionData.name, getPlainConditionObject, activePage]);

  return {
    conditionData,
    updateConditionData,
    handleOnSubmit,
    canSave,
    conditionsMetaData,
    handleOrCondition,
    handleAndCondition,
    handleDeleteCondition,
    updateConditionName,
    newlyCreatedSubConditionId,
  };
};

export default useAndOrGrouping;
