import React, { useCallback, useEffect, useState } from "react";
import { Platform, ProductData } from "../models";
import { getProductCollection, getVariant } from "../services/conditions.service";
import { ConditionFact } from "@reconvert/reconvert-utils";
import { APP_PLATFORM } from "../config";

interface ProductSelectorProps {
  conditionFact: string;
  value: {
    productIds: string[];
    variantIds: string[];
    productCollectionIds?: { id: string; name: string }[];
  };
  setValue: (val: {
    productIds: string[];
    variantIds: string[];
    productCollectionIds?: { id: string; name: string }[];
  }) => void;
}

interface ProductSelectorReturnType {
  selectedProductIdObj: Record<string, string[]>;
  isProductsLoading: boolean;
  selectedProducts: ProductData[];
  selectedProductCollections?: { id: string; name: string }[];
  handleRemoveProduct: (productId: string) => void;
  updateSelectedProductIdObj: (value: Record<string, string[]>) => void;
}

const useProductSelector = ({ value, setValue, conditionFact }: ProductSelectorProps): ProductSelectorReturnType => {
  const [selectedProductIdObj, setSelectedProductIdObj] = useState<Record<string, string[]>>(
    JSON.parse(JSON.stringify(value)),
  );

  const [selectedProducts, setSelectedProducts] = useState<ProductData[]>([]);
  const [selectedProductCollections, setSelectedProductCollections] = useState<{ id: string; name: string }[]>([]);
  const [isProductsLoading, setIsProductsLoading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedProducts([]);
    setSelectedProductIdObj({});
    setSelectedProductCollections([]);
  }, [conditionFact]);

  useEffect(() => {
    if (!value.productIds?.length) {
      setSelectedProducts([]);
    } else {
      const hasNewProduct = value.productIds.some(id => !selectedProducts.find(p => p.id === id));

      if (hasNewProduct) {
        getSelectedProducts();
      } else {
        setSelectedProducts(selectedProducts.filter(p => value.productIds.includes(p.id)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.productIds]);

  const getSelectedProductCollections = useCallback(async () => {
    const collectionsIds = value?.productCollectionIds?.map(a => a.id);

    const collectionsResponse = await getProductCollection({
      limit: APP_PLATFORM === Platform.Wix ? 100 : 250,
      collectionIds: collectionsIds?.length ? collectionsIds.join(",") : undefined,
    });

    setSelectedProductCollections(
      collectionsResponse?.collections.map((item: any) => {
        return {
          id: item.id.split("/").pop(),
          name: item.title,
        };
      }),
    );
  }, [value?.productCollectionIds]);

  useEffect(() => {
    (async () => {
      if (conditionFact === "PRODUCT_COLLECTION") {
        getSelectedProductCollections();
      }
    })();
  }, [conditionFact, getSelectedProductCollections, value.productCollectionIds]);

  const getSelectedProducts = useCallback(async () => {
    setIsProductsLoading(true);

    const regex = /^(0|-)+$/;
    let products: ProductData[] = [];

    let newSelectedProductIdObj: any = {};

    switch (conditionFact) {
      case "SPECIFIC_PRODUCT":
        const response = await getVariant(value.productIds);

        if (response) {
          products = response?.map((item: any) => {
            return {
              ...item,
              id: item?.id.split("/").pop(),
              variants: item?.variants?.map((variant: any) => {
                return {
                  ...variant,
                  id: regex.test(variant?.id?.split("/")?.pop())
                    ? item?.id.split("/").pop()
                    : variant?.id?.split("/")?.pop(),
                };
              }),
            };
          });

          value.productIds?.forEach((productId: any) => {
            products!.forEach((product: any) => {
              if (product.id === productId) {
                newSelectedProductIdObj[productId] = product.variants
                  .filter((variant: any) => value.variantIds.includes(variant?.id))
                  .map((value: any) => value.id);
              }
            });
          });
        }

        setSelectedProductIdObj(newSelectedProductIdObj);

        setSelectedProducts(products);

        break;

      case "PRODUCT_COLLECTION":
        getSelectedProductCollections();

        break;
    }

    setIsProductsLoading(false);
  }, [value]);

  const updateSelectedProductIdObj = (value: Record<string, string[]>) => {
    setSelectedProductIdObj(value);
    const productIds = Object.keys(value)?.filter(key => key !== "productIds" && key !== "variantIds");
    const variantIds = Object.values(value).flat();

    if (conditionFact === ConditionFact.SPECIFIC_PRODUCT) {
      setValue({ productIds, variantIds });
    }

    if (conditionFact === ConditionFact.PRODUCT_COLLECTION) {
      const data = Object.entries(value).map(([key, value]) => ({
        id: key,
        name: value[0],
      }));

      setValue({ productCollectionIds: data, productIds: [], variantIds: [] });
    }
  };

  const handleRemoveProduct = (productId: string) => {
    switch (conditionFact) {
      case ConditionFact.SPECIFIC_PRODUCT:
        const newValue = { ...selectedProductIdObj };

        delete newValue[productId];
        updateSelectedProductIdObj(newValue);
        break;

      case ConditionFact.PRODUCT_COLLECTION:
        setSelectedProductCollections(prev => prev.filter(a => a.id !== productId));
        updateSelectedProductIdObj(
          selectedProductCollections
            .filter(a => a.id !== productId)
            ?.reduce((acc, item) => {
              acc[item.id] = acc[item.id] || [];
              acc[item.id].push(item.name);
              return acc;
            }, {} as any),
        );
        break;

      default:
        break;
    }
  };

  return {
    selectedProductIdObj,
    isProductsLoading,
    selectedProducts,
    selectedProductCollections,
    handleRemoveProduct,
    updateSelectedProductIdObj,
  };
};

export default useProductSelector;
